import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from '@react-google-maps/api'
import {useContext, useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import LocationDetails from './locationDetails/LocationDetails'
import {NotificationsContext} from '../../../context/NotificationsContext'
import ResetConfirmationModal from '../modals/resetConfirmationModal/ResetConfirmationModal'

const SingleGoogleMap = ({incident}) => {
  // const [togglePrimaryStation, setTogglePrimaryStation] = useState(false)
  // Context
  const {incidentInfo} = useContext(NotificationsContext)
  // Traffic Layer
  const [removeLayer, setRemoveLayer] = useState(true)
  // Modal
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // Google map direction
  const [direction, setDirection] = useState(null)
  const [routeInfo, setRouteInfo] = useState([])
  const [center, setCenter] = useState({})
  const libraries = ['places']
  const {currentUser} = useAuth()

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  })

  const calculateRoute = async () => {
    // eslint-disable-next-line no-undef
    const start = new google.maps.LatLng(incident?.station?.latitude, incident?.station?.longitude)
    // eslint-disable-next-line no-undef
    const end = new google.maps.LatLng(
      incident?.incident_place?.latitude,
      incident?.incident_place?.longitude
    )
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const result = await directionsService.route({
      origin: start,
      destination: end,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    // console.log(result)
    setDirection(result)
    setRouteInfo(result.routes[0].legs[0])
  }

  useEffect(() => {
    if (currentUser?.station) {
      setCenter({lat: currentUser?.station?.latitude, lng: currentUser?.station?.longitude})
    }
    setCenter({lat: 23.7778, lng: 90.4057})
  }, [currentUser])

  useEffect(() => {
    if (isLoaded) calculateRoute()
  }, [isLoaded, incident])

  // useEffect(() => {
  //   if (
  //     incidentInfo?.length > 0 &&
  //     !incidentInfo[0]?.others?.is_new_incident &&
  //     !incidentInfo[0]?.others?.is_reset
  //   ) {
  //     // If primary station did not receive the call then changes in secondary station and hq
  //     if (!incidentInfo[0]?.others?.accept_by_other && !incidentInfo[0]?.others?.can_skip) {
  //       setTogglePrimaryStation(true)
  //       // if (incident?.incident_place.id === incidentInfo[0]?.incident_place.id) {
  //       //   setTogglePrimaryStation(true)
  //       // } else setTogglePrimaryStation(false)
  //     }
  //     // If primary station did not receive the call then changes in secondary station
  //     if (
  //       incidentInfo[0]?.others?.accept_by_other &&
  //       incidentInfo[0]?.others?.can_skip &&
  //       incidentInfo[0]?.station?.order_number === 1
  //     ) {
  //       setTogglePrimaryStation(true)
  //       // if (incident?.incident_place.id === incidentInfo[0]?.incident_place.id) {
  //       //   setTogglePrimaryStation(true)
  //       // } else setTogglePrimaryStation(false)
  //     }
  //   }
  // }, [incidentInfo])

  if (!isLoaded) {
    return <h1 className='map-container'>Loading...</h1>
  }
  return (
    <GoogleMap
      center={center}
      zoom={13}
      mapContainerStyle={{width: '100%', height: '100%'}}
      options={{streetViewControl: false, mapTypeControl: false}}
    >
      {!direction && <Marker position={center} />}

      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            polylineOptions: {
              strokeOpacity: 0.9,
              strokeColor: '#6610F2',
            },
          }}
        />
      )}
      {/* Red gif on incident place for both stations */}
      {direction && (
        <>
          <Marker
            icon={{
              url: require('../../../../_sos/assets/images/flame3.gif'),
              // eslint-disable-next-line no-undef
              anchor: new google.maps.Point(30, 30),
              // eslint-disable-next-line no-undef
              scaledSize: new google.maps.Size(50, 50),
            }}
            position={{
              lat: incident?.incident_place?.latitude,
              lng: incident?.incident_place?.longitude,
            }}
          />
          <Marker
            icon={{
              url: require('../../../../_sos/assets/images/fire-truck.png'),
              // eslint-disable-next-line no-undef
              anchor: new google.maps.Point(10, 10),
              // eslint-disable-next-line no-undef
              scaledSize: new google.maps.Size(45, 45),
            }}
            position={{
              lat: incident?.station?.latitude,
              lng: incident?.station?.longitude,
            }}
          />
        </>
      )}
      {/* Red gif on the primary station, yellow for the standby station */}
      {/* {direction && incident?.station?.order_number === 1 && (
        <Marker
          icon={{
            url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Flashspot.gif/120px-Flashspot.gif',
            // eslint-disable-next-line no-undef
            anchor: new google.maps.Point(19, 20),
            // eslint-disable-next-line no-undef
            scaledSize: new google.maps.Size(37, 37),
          }}
          position={{
            lat: incident?.station?.latitude,
            lng: incident?.station?.longitude,
          }}
        />
      )}
      {direction && incident?.station?.order_number === 2 && (
        <Marker
          icon={{
            url: 'https://media1.giphy.com/media/ukkGS6YNJoaoHbDLs0/giphy.gif?cid=790b76114fd7c4f3f06637905f6f7b2e8419c60243c9a087&rid=giphy.gif&ct=s',
            // eslint-disable-next-line no-undef
            anchor: new google.maps.Point(25, 10),
            // eslint-disable-next-line no-undef
            scaledSize: new google.maps.Size(70, 70),
          }}
          position={{
            lat: incident?.station?.latitude,
            lng: incident?.station?.longitude,
          }}
        />
      )} */}
      {removeLayer && <TrafficLayer autoUpdate />}

      {incident && <LocationDetails info={incident} routeInfo={routeInfo} />}

      <div className='btn-container'>
        <button
          onClick={() => setRemoveLayer(!removeLayer)}
          className={removeLayer ? 'traffic-button remove-layer' : 'traffic-button add-layer'}
        >
          <span>{removeLayer ? 'Remove' : 'Add'}</span> Traffic Layer
        </button>
        {(currentUser.role === 'Head quarter' || currentUser.role === 'ADN Head Quarter') &&
          !incident?.togglePrimaryStation &&
          incident?.station?.order_number === 1 && (
            <button onClick={handleShow} className='confirmation-btn'>
              Mark as Complete
            </button>
          )}
        {(currentUser.role === 'Head quarter' || currentUser.role === 'ADN Head Quarter') &&
          incident?.standby &&
          incident?.station?.order_number === 2 && (
            <button onClick={handleShow} className='confirmation-btn'>
              Mark as Complete
            </button>
          )}
      </div>

      {incidentInfo && (
        <ResetConfirmationModal
          show={show}
          handleClose={handleClose}
          notification={incident}
          currentUser={currentUser}
        />
      )}
    </GoogleMap>
  )
}

export default SingleGoogleMap
