import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const resetIncident = (incident) => {
  const alarmId = incident?.station?.alarm
  const REST_INCIDENT = `${API_URL}/incident-places/incident-reset/${alarmId}/`

  return axios.get(REST_INCIDENT)
}
