export const railwayStations = {
  type: 'FeatureCollection',
  name: 'Rail_Stations',
  crs: {
    type: 'name',
    properties: {
      name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
    },
  },
  features: [
    {
      type: 'Feature',
      properties: {
        STN_ID: 86,
        SL_NO: 86,
        STATIONNAM: 'DAMUKDIA',
        REMARKS: null,
        Station_Na: 'Damukdia',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.98733506003988, 24.095497365753587],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 93,
        SL_NO: 93,
        STATIONNAM: 'MONSHIGANJ',
        REMARKS: null,
        Station_Na: 'Monshiganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.92071545145104, 23.739406378423794],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 107,
        SL_NO: 107,
        STATIONNAM: 'PANGSA',
        REMARKS: null,
        Station_Na: 'Pangsa',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.4296875436689, 23.784662092033468],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 115,
        SL_NO: 115,
        STATIONNAM: 'BELGACHI',
        REMARKS: null,
        Station_Na: 'Belgachi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.51564024317858, 23.76814271319279],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 116,
        SL_NO: 116,
        STATIONNAM: 'SURJANAGAR',
        REMARKS: null,
        Station_Na: 'Surjanagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.61631788658819, 23.76929278919587],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 117,
        SL_NO: 117,
        STATIONNAM: 'RAJBARI',
        REMARKS: null,
        Station_Na: 'Rajbari',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.64875017438685, 23.762071626397134],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 119,
        SL_NO: 119,
        STATIONNAM: 'GOALUNDA BAZAR',
        REMARKS: null,
        Station_Na: 'Goalunda bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.76443491877978, 23.721105369441815],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 120,
        SL_NO: 120,
        STATIONNAM: 'GOALUNAGHAT',
        REMARKS: null,
        Station_Na: 'Goalunaghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.78535462127266, 23.763538484698124],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 121,
        SL_NO: 121,
        STATIONNAM: 'KHANKANAPUR',
        REMARKS: null,
        Station_Na: 'Khankanapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.71057893693249, 23.679737045890494],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 122,
        SL_NO: 122,
        STATIONNAM: 'BASANTAPUR',
        REMARKS: null,
        Station_Na: 'Basantapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.74983226903127, 23.637803790110127],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 123,
        SL_NO: 123,
        STATIONNAM: 'AMIRABAD',
        REMARKS: null,
        Station_Na: 'Amirabad',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.78365316035962, 23.61788155973767],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 124,
        SL_NO: 124,
        STATIONNAM: 'AMBIKAPUR',
        REMARKS: null,
        Station_Na: 'Ambikapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.82203665789487, 23.609941283500394],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 125,
        SL_NO: 125,
        STATIONNAM: 'FARIDPUR',
        REMARKS: null,
        Station_Na: 'Faridpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.8608626558682, 23.60305783178413],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 126,
        SL_NO: 126,
        STATIONNAM: 'BAKHUNDA',
        REMARKS: null,
        Station_Na: 'Bakhunda',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.86736301933828, 23.5144098818259],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 145,
        SL_NO: 145,
        STATIONNAM: 'MANSUR ALI',
        REMARKS: null,
        Station_Na: 'Mansur Ali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.69008767388247, 24.39741767881289],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 147,
        SL_NO: 147,
        STATIONNAM: 'TALMA',
        REMARKS: null,
        Station_Na: 'Talma',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.89057155839687, 23.485357511654453],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 146,
        SL_NO: 146,
        STATIONNAM: 'B.B/EAST',
        REMARKS: null,
        Station_Na: 'B.b/East',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.84559630561898, 24.383500835254534],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 229,
        SL_NO: 229,
        STATIONNAM: 'MASHIUR NAGAR',
        REMARKS: null,
        Station_Na: 'Mashiur nagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.22767639834395, 24.842834418978338],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 234,
        SL_NO: 234,
        STATIONNAM: 'KRISHI BISHWABIDYALA',
        REMARKS: null,
        Station_Na: 'Krishi bishwabidyala',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.44553368574809, 24.70946858128113],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 235,
        SL_NO: 235,
        STATIONNAM: 'SUTIAKHALI',
        REMARKS: null,
        Station_Na: 'Sutiakhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.45863349988517, 24.672885740999604],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 236,
        SL_NO: 236,
        STATIONNAM: 'FATIMANAGAR',
        REMARKS: null,
        Station_Na: 'Fatimanagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.47612014448163, 24.620448802845967],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 239,
        SL_NO: 239,
        STATIONNAM: 'DHALA',
        REMARKS: null,
        Station_Na: 'Dhala',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.51192492978367, 24.519016359939567],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 243,
        SL_NO: 243,
        STATIONNAM: 'SATKHAMAR',
        REMARKS: null,
        Station_Na: 'Satkhamar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.49513259092896, 24.24579627375406],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 267,
        SL_NO: 267,
        STATIONNAM: 'AMIRGANJ',
        REMARKS: null,
        Station_Na: 'Amirganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.75823230386263, 23.932048770592434],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 274,
        SL_NO: 274,
        STATIONNAM: 'BISHKA',
        REMARKS: null,
        Station_Na: 'Bishka',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.52329257069516, 24.7565881695835],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 276,
        SL_NO: 276,
        STATIONNAM: 'SHAMGANJ',
        REMARKS: 'JUNCTION',
        Station_Na: 'Shamganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.58160384768473, 24.84303849864083],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 277,
        SL_NO: 277,
        STATIONNAM: 'JALSUKA',
        REMARKS: null,
        Station_Na: 'Jalsuka',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.58282444016965, 24.86772556724523],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 278,
        SL_NO: 278,
        STATIONNAM: 'PURBADHALA',
        REMARKS: null,
        Station_Na: 'Purbadhala',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.61135073430144, 24.961236972730564],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 301,
        SL_NO: 301,
        STATIONNAM: 'HALIMPUR MOKSUD',
        REMARKS: null,
        Station_Na: 'Halimpur moksud',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.86596704206491, 24.272762329382154],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 302,
        SL_NO: 302,
        STATIONNAM: 'SARASCHAR',
        REMARKS: null,
        Station_Na: 'Saraschar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.89510348048775, 24.23253062753076],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 312,
        SL_NO: 312,
        STATIONNAM: 'BHATSHALA',
        REMARKS: null,
        Station_Na: 'Bhatshala',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.16835808524858, 23.894958721028363],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 384,
        SL_NO: 384,
        STATIONNAM: 'MEHAR',
        REMARKS: null,
        Station_Na: 'Mehar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.91553513772057, 23.24834805399932],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 387,
        SL_NO: 387,
        STATIONNAM: 'BALAKHAL HAJIGANJ',
        REMARKS: null,
        Station_Na: 'Balakhal hajiganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.8017807729928, 23.250890584181906],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 390,
        SL_NO: 390,
        STATIONNAM: 'CHANDPUR COURT',
        REMARKS: null,
        Station_Na: 'Chandpur court',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.69219996101937, 23.232660342316645],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 420,
        SL_NO: 420,
        STATIONNAM: 'MASTANNAGAR',
        REMARKS: null,
        Station_Na: 'Mastannagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.59846481158112, 22.759452827695068],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 445,
        SL_NO: 445,
        STATIONNAM: 'GOMDANDI',
        REMARKS: null,
        Station_Na: 'Gomdandi',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.93512753293146, 22.364177667754852],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 448,
        SL_NO: 448,
        STATIONNAM: 'KHANMOHANA',
        REMARKS: null,
        Station_Na: 'Khanmohana',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.98184939779267, 22.3088378353686],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 450,
        SL_NO: 450,
        STATIONNAM: 'CHAKRASALA',
        REMARKS: null,
        Station_Na: 'Chakrasala',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.02056104569665, 22.255725762375757],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 452,
        SL_NO: 452,
        STATIONNAM: 'KANCHANNAGAR',
        REMARKS: null,
        Station_Na: 'Kanchannagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.04043560594825, 22.220668768874084],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 458,
        SL_NO: 458,
        STATIONNAM: 'BOALMARIBAZAR',
        REMARKS: null,
        Station_Na: 'Boalmaribazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.68939980124996, 23.394208814486845],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 465,
        SL_NO: 465,
        STATIONNAM: 'ANSARBARIA',
        REMARKS: null,
        Station_Na: 'Ansarbaria',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.89807125476501, 23.489740567832815],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 472,
        SL_NO: 472,
        STATIONNAM: 'JESSORE CANT',
        REMARKS: null,
        Station_Na: 'Jessore cant',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.19181836928634, 23.179742896057686],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 476,
        SL_NO: 476,
        STATIONNAM: 'GADKALI',
        REMARKS: null,
        Station_Na: 'Gadkali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.05802906500004, 23.06893161882291],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 477,
        SL_NO: 477,
        STATIONNAM: 'JHIKARGACHA',
        REMARKS: null,
        Station_Na: 'Jhikargacha',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.10977177487125, 23.09892441431058],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 210,
        SL_NO: 210,
        STATIONNAM: 'BURIMARI',
        REMARKS: null,
        Station_Na: 'Burimari',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.93602745182561, 26.39490905510125],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 83,
        SL_NO: 83,
        STATIONNAM: 'ISHURDI',
        REMARKS: 'JUNCTION',
        Station_Na: 'Ishurdi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.06338491339068, 24.1457692436834],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 90,
        SL_NO: 90,
        STATIONNAM: 'PORADAHA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Poradaha',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.03136430749844, 23.860652818400126],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 108,
        SL_NO: 108,
        STATIONNAM: 'KALUKHALI',
        REMARKS: null,
        Station_Na: 'Kalukhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.46069335361638, 23.773681435639258],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 113,
        SL_NO: 113,
        STATIONNAM: 'MADHUKHALI',
        REMARKS: null,
        Station_Na: 'Madhukhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.63423930071878, 23.53705601972321],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 118,
        SL_NO: 118,
        STATIONNAM: 'PANCHOORIA',
        REMARKS: null,
        Station_Na: 'Panchooria',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.71621695012544, 23.74690273942238],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 114,
        SL_NO: 114,
        STATIONNAM: 'KAMARKAHLI GHAT',
        REMARKS: null,
        Station_Na: 'Kamarkahli ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.54295339713744, 23.53236571148458],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 98,
        SL_NO: 98,
        STATIONNAM: 'DARSANA',
        REMARKS: null,
        Station_Na: 'Darsana',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.79544064053097, 23.506077035681674],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 97,
        SL_NO: 97,
        STATIONNAM: 'DARSANA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Darsana',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.80438220371171, 23.53823833768205],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 463,
        SL_NO: 463,
        STATIONNAM: 'DARSANA HALT',
        REMARKS: null,
        Station_Na: 'Darsana halt',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.81079095821312, 23.53024078052138],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 478,
        SL_NO: 478,
        STATIONNAM: 'JESSORE',
        REMARKS: null,
        Station_Na: 'Jessore',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.2056504034082, 23.156226883090987],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 480,
        SL_NO: 480,
        STATIONNAM: 'SINGIA',
        REMARKS: null,
        Station_Na: 'Singia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.35347741833652, 23.118295512403705],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 479,
        SL_NO: 479,
        STATIONNAM: 'RUPDIA',
        REMARKS: null,
        Station_Na: 'Rupdia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.27342978396125, 23.124769265689885],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 481,
        SL_NO: 481,
        STATIONNAM: 'CHENGUTIA',
        REMARKS: null,
        Station_Na: 'Chengutia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.37982173416134, 23.058976933753822],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 482,
        SL_NO: 482,
        STATIONNAM: 'NAWAPARA',
        REMARKS: null,
        Station_Na: 'Nawapara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.40023789452027, 23.02982548352875],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 484,
        SL_NO: 484,
        STATIONNAM: 'BEJERDANGA',
        REMARKS: null,
        Station_Na: 'Bejerdanga',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.43373873968412, 22.99340038112163],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 489,
        SL_NO: 489,
        STATIONNAM: 'KHULNA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Khulna',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5536879994484, 22.82537078181411],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 485,
        SL_NO: 485,
        STATIONNAM: 'PHUITALA',
        REMARKS: null,
        Station_Na: 'Phuitala',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.45114903803663, 22.9763298035945],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 486,
        SL_NO: 486,
        STATIONNAM: 'SIRAMANI',
        REMARKS: null,
        Station_Na: 'Siramani',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.48783109839754, 22.939302232841744],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 488,
        SL_NO: 488,
        STATIONNAM: 'DAULATPUR  COLLEGE',
        REMARKS: null,
        Station_Na: 'Daulatpur  college',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.528816309407, 22.870588315011158],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 487,
        SL_NO: 487,
        STATIONNAM: 'DAULATPUR',
        REMARKS: null,
        Station_Na: 'Daulatpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.51646413469905, 22.890867425005485],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 491,
        SL_NO: 491,
        STATIONNAM: 'RUPSA EAST',
        REMARKS: null,
        Station_Na: 'Rupsa east',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.60332485735125, 22.801538532053257],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 492,
        SL_NO: 492,
        STATIONNAM: 'KARNAPUR',
        REMARKS: null,
        Station_Na: 'Karnapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.63814536938281, 22.793598264771113],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 493,
        SL_NO: 493,
        STATIONNAM: 'SAMANTASENA',
        REMARKS: null,
        Station_Na: 'Samantasena',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.66674818777855, 22.79090876367732],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 494,
        SL_NO: 494,
        STATIONNAM: 'BHAIRDIA',
        REMARKS: null,
        Station_Na: 'Bhairdia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.69467929410376, 22.78212165975191],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 495,
        SL_NO: 495,
        STATIONNAM: 'MULGHAR',
        REMARKS: null,
        Station_Na: 'Mulghar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.71607978487633, 22.772268178710185],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 496,
        SL_NO: 496,
        STATIONNAM: 'KHANJAHANPUR',
        REMARKS: null,
        Station_Na: 'Khanjahanpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.71988690403415, 22.749082587665786],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 497,
        SL_NO: 497,
        STATIONNAM: 'JATRAPUR',
        REMARKS: null,
        Station_Na: 'Jatrapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.73130802349381, 22.727514283183023],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 498,
        SL_NO: 498,
        STATIONNAM: 'SATGUMBAZ ROAD',
        REMARKS: null,
        Station_Na: 'Satgumbaz road',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.76348885409895, 22.712505415631963],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 499,
        SL_NO: 499,
        STATIONNAM: 'BAGERHAT COLLEGE',
        REMARKS: null,
        Station_Na: 'Bagerhat college',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.77650441798438, 22.67564193035262],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 500,
        SL_NO: 500,
        STATIONNAM: 'BAGERHAT',
        REMARKS: null,
        Station_Na: 'Bagerhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.80204015945063, 22.655794032240987],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 460,
        SL_NO: 460,
        STATIONNAM: 'BYASPUR',
        REMARKS: null,
        Station_Na: 'Byaspur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.75864411022717, 23.276134639971275],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 461,
        SL_NO: 461,
        STATIONNAM: 'KASIANI',
        REMARKS: null,
        Station_Na: 'Kasiani',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.73174274605759, 23.23604025433597],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 459,
        SL_NO: 459,
        STATIONNAM: 'SAHASRAK',
        REMARKS: null,
        Station_Na: 'Sahasrak',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.72519691493969, 23.31976343165479],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 219,
        SL_NO: 219,
        STATIONNAM: 'JAMALPUR TOWN',
        REMARKS: 'JUNCTION',
        Station_Na: 'Jamalpur Town',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.9536895874051, 24.916012015760206],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 275,
        SL_NO: 275,
        STATIONNAM: 'GURIPUR MYMENSINGH',
        REMARKS: 'JUNCTION',
        Station_Na: 'Guripur mymensingh',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.56499461322714, 24.75725186167999],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 279,
        SL_NO: 279,
        STATIONNAM: 'JARIA JHANJAIL',
        REMARKS: null,
        Station_Na: 'Jaria jhanjail',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.64709484622502, 25.008935829078183],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 280,
        SL_NO: 280,
        STATIONNAM: 'HEEARANPUR',
        REMARKS: null,
        Station_Na: 'Heearanpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.6401063332581, 24.845733578341584],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 281,
        SL_NO: 281,
        STATIONNAM: 'CHALLISNAGAR',
        REMARKS: null,
        Station_Na: 'Challisnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.68164854731134, 24.869348364218816],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 282,
        SL_NO: 282,
        STATIONNAM: 'NETRAKONA',
        REMARKS: null,
        Station_Na: 'Netrakona',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.730972019384, 24.886518415568183],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 283,
        SL_NO: 283,
        STATIONNAM: 'NETRAKONA COURT',
        REMARKS: null,
        Station_Na: 'Netrakona court',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.7505644261992, 24.900880698662966],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 284,
        SL_NO: 284,
        STATIONNAM: 'BANGLA',
        REMARKS: null,
        Station_Na: 'Bangla',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.79483011265845, 24.90994822636544],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 285,
        SL_NO: 285,
        STATIONNAM: 'THAKURAKONA',
        REMARKS: null,
        Station_Na: 'Thakurakona',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.82681259318149, 24.909873710866982],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 286,
        SL_NO: 286,
        STATIONNAM: 'BARHATTA',
        REMARKS: null,
        Station_Na: 'Barhatta',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.87429054069176, 24.899803376605384],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 287,
        SL_NO: 287,
        STATIONNAM: 'ATITPUR',
        REMARKS: null,
        Station_Na: 'Atitpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.89794893722764, 24.890848277278337],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 289,
        SL_NO: 289,
        STATIONNAM: 'BOKAINAGAR',
        REMARKS: null,
        Station_Na: 'Bokainagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.97861502504945, 24.87512803011696],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 288,
        SL_NO: 288,
        STATIONNAM: 'MOHANGANIA',
        REMARKS: null,
        Station_Na: 'Mohangania',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.93280013239396, 24.87318209206023],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 325,
        SL_NO: 325,
        STATIONNAM: 'SHABAZPUR',
        REMARKS: null,
        Station_Na: 'Shabazpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.27594779407774, 24.801775029630605],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 455,
        SL_NO: 455,
        STATIONNAM: 'DOHAZARI',
        REMARKS: null,
        Station_Na: 'Dohazari',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.07325755761708, 22.16216857413732],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 434,
        SL_NO: 434,
        STATIONNAM: 'SOLASHAHAR',
        REMARKS: 'JUNCTION',
        Station_Na: 'Solashahar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.84980022659643, 22.38343618709453],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 454,
        SL_NO: 454,
        STATIONNAM: 'HASHIMPUR',
        REMARKS: null,
        Station_Na: 'Hashimpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.06164524256867, 22.183292293608392],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 453,
        SL_NO: 453,
        STATIONNAM: 'KHANHAT',
        REMARKS: null,
        Station_Na: 'Khanhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.05237550246613, 22.199909283583523],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 451,
        SL_NO: 451,
        STATIONNAM: 'KHARANA',
        REMARKS: null,
        Station_Na: 'Kharana',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.02654285146049, 22.24344071619946],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 449,
        SL_NO: 449,
        STATIONNAM: 'PATIYA',
        REMARKS: null,
        Station_Na: 'Patiya',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.01028415049856, 22.276512181023296],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 447,
        SL_NO: 447,
        STATIONNAM: 'DALGHAT',
        REMARKS: null,
        Station_Na: 'Dalghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.96717079284537, 22.32877348914827],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 446,
        SL_NO: 446,
        STATIONNAM: 'BENGURA',
        REMARKS: null,
        Station_Na: 'Bengura',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.95126367354726, 22.347583751759238],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 444,
        SL_NO: 444,
        STATIONNAM: 'JANALIHAT',
        REMARKS: null,
        Station_Na: 'Janalihat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.88069908031794, 22.397127176335715],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 433,
        SL_NO: 433,
        STATIONNAM: 'JHAUTALA',
        REMARKS: null,
        Station_Na: 'Jhautala',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.83404525571935, 22.36614810124654],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 432,
        SL_NO: 432,
        STATIONNAM: 'CHITTAGONG',
        REMARKS: null,
        Station_Na: 'Chittagong',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.80092591019174, 22.35433953632261],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 431,
        SL_NO: 431,
        STATIONNAM: 'PAHARTALI',
        REMARKS: null,
        Station_Na: 'Pahartali',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.7847824758848, 22.36737259333144],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 430,
        SL_NO: 430,
        STATIONNAM: 'KAIBALYDHAM',
        REMARKS: null,
        Station_Na: 'Kaibalydham',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.76620512719614, 22.39113808115765],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 429,
        SL_NO: 429,
        STATIONNAM: 'FAUJDARHAT',
        REMARKS: null,
        Station_Na: 'Faujdarhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.75649261298598, 22.419179830523575],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 428,
        SL_NO: 428,
        STATIONNAM: 'BHATIYARI',
        REMARKS: null,
        Station_Na: 'Bhatiyari',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.74611643998024, 22.445621472245556],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 427,
        SL_NO: 427,
        STATIONNAM: 'KUMIRA',
        REMARKS: null,
        Station_Na: 'Kumira',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.72372418999834, 22.495002763102214],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 438,
        SL_NO: 438,
        STATIONNAM: 'CTG. UNIVERSITY',
        REMARKS: null,
        Station_Na: 'Ctg. university',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.79369024748837, 22.470509904676835],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 437,
        SL_NO: 437,
        STATIONNAM: 'FATIABAD',
        REMARKS: null,
        Station_Na: 'Fatiabad',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.81995867693998, 22.455034219619236],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 436,
        SL_NO: 436,
        STATIONNAM: 'CHOWDHURI HAT',
        REMARKS: null,
        Station_Na: 'Chowdhuri hat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.8190410176241, 22.424794530656932],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 435,
        SL_NO: 435,
        STATIONNAM: 'CHITTAGONG CANT',
        REMARKS: null,
        Station_Na: 'Chittagong cant',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.82791600131277, 22.40030843499969],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 443,
        SL_NO: 443,
        STATIONNAM: 'NAZIRHAT',
        REMARKS: null,
        Station_Na: 'Nazirhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.79335029281125, 22.6156080831021],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 442,
        SL_NO: 442,
        STATIONNAM: 'KATIRHAT',
        REMARKS: null,
        Station_Na: 'Katirhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.79592123810829, 22.57771869194371],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 439,
        SL_NO: 439,
        STATIONNAM: 'HATHAZARI',
        REMARKS: null,
        Station_Na: 'Hathazari',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.81199663952403, 22.491065885108586],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 440,
        SL_NO: 440,
        STATIONNAM: 'CHARIMADRASA',
        REMARKS: null,
        Station_Na: 'Charimadrasa',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.80875412275452, 22.504909416102766],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 441,
        SL_NO: 441,
        STATIONNAM: 'SARKARHAT',
        REMARKS: null,
        Station_Na: 'Sarkarhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.80191061609325, 22.544710033131782],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 426,
        SL_NO: 426,
        STATIONNAM: 'BARABKUND',
        REMARKS: null,
        Station_Na: 'Barabkund',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.68846103862776, 22.56695565742123],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 425,
        SL_NO: 425,
        STATIONNAM: 'SITAKUND',
        REMARKS: null,
        Station_Na: 'Sitakund',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.67749804728194, 22.59471879097246],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 424,
        SL_NO: 424,
        STATIONNAM: 'BARAIYADHALA',
        REMARKS: null,
        Station_Na: 'Baraiyadhala',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.66756464235405, 22.61486060588693],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 423,
        SL_NO: 423,
        STATIONNAM: 'NIZAMPUR COLLEGE',
        REMARKS: null,
        Station_Na: 'Nizampur college',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.65531142460662, 22.640428410786903],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 422,
        SL_NO: 422,
        STATIONNAM: 'BAROTAKIA',
        REMARKS: null,
        Station_Na: 'Barotakia',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.6398389102718, 22.672525270060525],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 421,
        SL_NO: 421,
        STATIONNAM: 'MIRSARAI',
        REMARKS: null,
        Station_Na: 'Mirsarai',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.62001017769359, 22.714433770490288],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 419,
        SL_NO: 419,
        STATIONNAM: 'CHINKIASTANA',
        REMARKS: null,
        Station_Na: 'Chinkiastana',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.58233635094125, 22.79318041073347],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 418,
        SL_NO: 418,
        STATIONNAM: 'MUHURIGANJ',
        REMARKS: null,
        Station_Na: 'Muhuriganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.55096440178569, 22.86056321162285],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 417,
        SL_NO: 417,
        STATIONNAM: 'FAZILPUR',
        REMARKS: null,
        Station_Na: 'Fazilpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.53820807976118, 22.895631747801016],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 416,
        SL_NO: 416,
        STATIONNAM: 'KALIDAHA',
        REMARKS: null,
        Station_Na: 'Kalidaha',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.52419276860765, 22.926250215659007],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 407,
        SL_NO: 407,
        STATIONNAM: 'SAIRSHADI',
        REMARKS: null,
        Station_Na: 'Sairshadi',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.3701552720236, 23.03860255274867],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 408,
        SL_NO: 408,
        STATIONNAM: 'FENI',
        REMARKS: 'JUNCTION',
        Station_Na: 'Feni',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.40937059625898, 23.011764461483292],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 501,
        SL_NO: 501,
        STATIONNAM: 'BELONIA',
        REMARKS: null,
        Station_Na: 'Belonia',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.4585189675533, 23.21549616028598],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 409,
        SL_NO: 409,
        STATIONNAM: 'BANDUA DAULATPUR',
        REMARKS: null,
        Station_Na: 'Bandua daulatpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.4186779805386, 23.03450370877828],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 415,
        SL_NO: 415,
        STATIONNAM: 'PARSHURAM',
        REMARKS: null,
        Station_Na: 'Parshuram',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.4443743368479, 23.191291572946657],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 414,
        SL_NO: 414,
        STATIONNAM: 'CHITHOLIA',
        REMARKS: null,
        Station_Na: 'Chitholia',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.44342773927603, 23.176321135166543],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 413,
        SL_NO: 413,
        STATIONNAM: 'FULGAZI',
        REMARKS: null,
        Station_Na: 'Fulgazi',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.44287135678798, 23.127174650099125],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 412,
        SL_NO: 412,
        STATIONNAM: 'NEW MNSHIR HAT',
        REMARKS: null,
        Station_Na: 'New mnshir hat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.44376344171319, 23.09810273981854],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 411,
        SL_NO: 411,
        STATIONNAM: 'PEERBUKSH HAT',
        REMARKS: null,
        Station_Na: 'Peerbuksh hat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.44200874504992, 23.0737211790636],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 410,
        SL_NO: 410,
        STATIONNAM: 'ANANDAPUR',
        REMARKS: null,
        Station_Na: 'Anandapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.4373782199839, 23.05188735797798],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 381,
        SL_NO: 381,
        STATIONNAM: 'LAKSAM',
        REMARKS: 'JUNCTION',
        Station_Na: 'Laksam',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.12405491191001, 23.25461740764521],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 406,
        SL_NO: 406,
        STATIONNAM: 'GUNABATI',
        REMARKS: null,
        Station_Na: 'Gunabati',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.31304155198426, 23.07847962666603],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 403,
        SL_NO: 403,
        STATIONNAM: 'NAOTI',
        REMARKS: null,
        Station_Na: 'Naoti',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.18999500531261, 23.17765821227723],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 404,
        SL_NO: 404,
        STATIONNAM: 'NANGALKOT',
        REMARKS: null,
        Station_Na: 'Nangalkot',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.20474259696272, 23.165256514524938],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 405,
        SL_NO: 405,
        STATIONNAM: 'HASANPUR',
        REMARKS: null,
        Station_Na: 'Hasanpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.2866970906092, 23.096948446517345],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 398,
        SL_NO: 398,
        STATIONNAM: 'CHOWMUHAN',
        REMARKS: null,
        Station_Na: 'Chowmuhan',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.12372590358342, 22.946267815766817],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 399,
        SL_NO: 399,
        STATIONNAM: 'MAIJDEE',
        REMARKS: null,
        Station_Na: 'Maijdee',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.11055771720251, 22.885957959063703],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 400,
        SL_NO: 400,
        STATIONNAM: 'MAIJDEE COURT',
        REMARKS: null,
        Station_Na: 'Maijdee court',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.1069566438987, 22.87103252423913],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 402,
        SL_NO: 402,
        STATIONNAM: 'NOAKHALI',
        REMARKS: null,
        Station_Na: 'Noakhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.10476659740372, 22.824857871740694],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 401,
        SL_NO: 401,
        STATIONNAM: 'HARINARAYANPUR',
        REMARKS: null,
        Station_Na: 'Harinarayanpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.10300428039832, 22.848251587401535],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 397,
        SL_NO: 397,
        STATIONNAM: 'BAJRA',
        REMARKS: null,
        Station_Na: 'Bajra',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.10231017155513, 23.014950073686514],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 396,
        SL_NO: 396,
        STATIONNAM: 'SONAIMURI',
        REMARKS: null,
        Station_Na: 'Sonaimuri',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.11226652605357, 23.05459983858658],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 395,
        SL_NO: 395,
        STATIONNAM: 'BIPULASAR',
        REMARKS: null,
        Station_Na: 'Bipulasar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.11685961191856, 23.086688774343738],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 394,
        SL_NO: 394,
        STATIONNAM: 'NATHERPETUA',
        REMARKS: null,
        Station_Na: 'Natherpetua',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.12643430200475, 23.11826540995259],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 393,
        SL_NO: 393,
        STATIONNAM: 'KHILA',
        REMARKS: null,
        Station_Na: 'Khila',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.13610092734483, 23.16089844276814],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 392,
        SL_NO: 392,
        STATIONNAM: 'DAULATGANJ',
        REMARKS: null,
        Station_Na: 'Daulatganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.13433074761603, 23.214548121677534],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 377,
        SL_NO: 377,
        STATIONNAM: 'COMILLA',
        REMARKS: null,
        Station_Na: 'Comilla',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.16679336776987, 23.46364843495487],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 380,
        SL_NO: 380,
        STATIONNAM: 'ALISHAHAR',
        REMARKS: null,
        Station_Na: 'Alishahar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.138229108231, 23.306355458481065],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 379,
        SL_NO: 379,
        STATIONNAM: 'LALMAI',
        REMARKS: null,
        Station_Na: 'Lalmai',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.15155861289477, 23.356403991902337],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 378,
        SL_NO: 378,
        STATIONNAM: 'MANAMATI',
        REMARKS: null,
        Station_Na: 'Mainamati',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.17148658458949, 23.43079606989652],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 374,
        SL_NO: 374,
        STATIONNAM: 'SASIDAL',
        REMARKS: null,
        Station_Na: 'Shashidal',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.14691809782326, 23.6393457486327],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 375,
        SL_NO: 375,
        STATIONNAM: 'RAJAPUR',
        REMARKS: null,
        Station_Na: 'Rajapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.15208008606139, 23.580877930414054],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 373,
        SL_NO: 373,
        STATIONNAM: 'SALDANADI',
        REMARKS: null,
        Station_Na: 'Salda Nadi',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.15583696164973, 23.670811535909902],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 376,
        SL_NO: 376,
        STATIONNAM: 'FAKIRHAT',
        REMARKS: null,
        Station_Na: 'Sadar Rashulpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.16863977578709, 23.519166748205826],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 372,
        SL_NO: 372,
        STATIONNAM: 'MANDABAG',
        REMARKS: null,
        Station_Na: 'Mandabag',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.15232746106979, 23.688223658639465],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 367,
        SL_NO: 367,
        STATIONNAM: 'AZIMPUR',
        REMARKS: null,
        Station_Na: 'Azimpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.2253724620731, 23.89865847860144],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 368,
        SL_NO: 368,
        STATIONNAM: 'AKHAURA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Akhaura',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.20588773049134, 23.86939026317201],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 369,
        SL_NO: 369,
        STATIONNAM: 'GANGASAGAR',
        REMARKS: null,
        Station_Na: 'Gangasagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.19566897578318, 23.830227555044583],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 370,
        SL_NO: 370,
        STATIONNAM: 'IMAMBARI',
        REMARKS: null,
        Station_Na: 'Imambari',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.17314231019519, 23.792354732482085],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 371,
        SL_NO: 371,
        STATIONNAM: 'QUASBA',
        REMARKS: null,
        Station_Na: 'Quasba',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.1558112380977, 23.740301842151265],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 307,
        SL_NO: 307,
        STATIONNAM: 'BHAIRAB BAZAR',
        REMARKS: 'JUNCTION',
        Station_Na: 'Bhairab bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.97834798753165, 24.058694699921343],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 310,
        SL_NO: 310,
        STATIONNAM: 'BRAHMNBARIA',
        REMARKS: null,
        Station_Na: 'Brahmnbaria',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.11419665583841, 23.96101170270946],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 309,
        SL_NO: 309,
        STATIONNAM: 'TALSHAHAR',
        REMARKS: null,
        Station_Na: 'Talshahar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.03972601538298, 24.011829583086868],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 308,
        SL_NO: 308,
        STATIONNAM: 'ASHUGANJ',
        REMARKS: null,
        Station_Na: 'Ashuganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.01667758921074, 24.029186472325737],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 311,
        SL_NO: 311,
        STATIONNAM: 'PAGHACHANG',
        REMARKS: null,
        Station_Na: 'Paghachang',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.14089942562315, 23.925682308358233],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 366,
        SL_NO: 366,
        STATIONNAM: 'SINGARBEEL',
        REMARKS: null,
        Station_Na: 'Singarbeel',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.23051431988586, 23.914184339959686],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 365,
        SL_NO: 365,
        STATIONNAM: 'MERASANI',
        REMARKS: null,
        Station_Na: 'Merasani',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.23464957221879, 23.926017835156667],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 364,
        SL_NO: 364,
        STATIONNAM: 'MUKUNDAPUR',
        REMARKS: null,
        Station_Na: 'Mukundapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.26722689300257, 23.96437047405326],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 363,
        SL_NO: 363,
        STATIONNAM: 'HARASPUR',
        REMARKS: null,
        Station_Na: 'Haraspur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.305725129284, 24.00806816657819],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 361,
        SL_NO: 361,
        STATIONNAM: 'MONTALA',
        REMARKS: null,
        Station_Na: 'Montala',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.35366837269768, 24.085162855396803],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 362,
        SL_NO: 362,
        STATIONNAM: 'QASIMNAGAR',
        REMARKS: null,
        Station_Na: 'Qasimnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.34143820292823, 24.054790317381443],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 356,
        SL_NO: 356,
        STATIONNAM: 'SATANGBAZAR',
        REMARKS: null,
        Station_Na: 'Satangbazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.37022395857252, 24.214466003909457],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 357,
        SL_NO: 357,
        STATIONNAM: 'CHAIAN NOYAPARA',
        REMARKS: null,
        Station_Na: 'Chaian noyapara',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.3619229942748, 24.18834665485302],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 358,
        SL_NO: 358,
        STATIONNAM: 'ITACOLA',
        REMARKS: null,
        Station_Na: 'Itacola',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.36355610256652, 24.160142849546205],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 359,
        SL_NO: 359,
        STATIONNAM: 'TALIAPARA',
        REMARKS: null,
        Station_Na: 'Taliapara',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.3700943992501, 24.125268898765057],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 360,
        SL_NO: 360,
        STATIONNAM: 'SHAHPUR',
        REMARKS: null,
        Station_Na: 'Shahpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.36198436114525, 24.103597524008403],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 346,
        SL_NO: 346,
        STATIONNAM: 'SHAISTAGANJ',
        REMARKS: 'JUNCTION',
        Station_Na: 'Shaistaganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.455352780347, 24.27748853388109],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 342,
        SL_NO: 342,
        STATIONNAM: 'HABIGANJ BAZAR',
        REMARKS: null,
        Station_Na: 'Habiganj bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.42095174204087, 24.36248207237506],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 345,
        SL_NO: 345,
        STATIONNAM: 'PAIKPARA',
        REMARKS: null,
        Station_Na: 'Paikpara',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.4409334704338, 24.29801199870563],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 344,
        SL_NO: 344,
        STATIONNAM: 'DHULI KHAL',
        REMARKS: null,
        Station_Na: 'Dhuli khal',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.43540168458831, 24.320059058618217],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 343,
        SL_NO: 343,
        STATIONNAM: 'HABIGANJ COURT',
        REMARKS: null,
        Station_Na: 'Habiganj court',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.42923744428956, 24.342117529478987],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 354,
        SL_NO: 354,
        STATIONNAM: 'SUTANG',
        REMARKS: null,
        Station_Na: 'Sutang',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.40939350548172, 24.28323750922382],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 355,
        SL_NO: 355,
        STATIONNAM: 'SHAHJIBAZAR',
        REMARKS: null,
        Station_Na: 'Shahjibazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.38768773902545, 24.2630711475407],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 353,
        SL_NO: 353,
        STATIONNAM: 'BALLA',
        REMARKS: null,
        Station_Na: 'Balla',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.5945661376602, 24.08380503412952],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 347,
        SL_NO: 347,
        STATIONNAM: 'BARAKOTA',
        REMARKS: null,
        Station_Na: 'Barakota',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.47067248646371, 24.2542249947875],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 348,
        SL_NO: 348,
        STATIONNAM: 'SAKIR',
        REMARKS: null,
        Station_Na: 'Sakir',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.47357157821222, 24.224454970584326],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 349,
        SL_NO: 349,
        STATIONNAM: 'MOHD',
        REMARKS: null,
        Station_Na: 'Mohd',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.48390211257347, 24.194742475993046],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 350,
        SL_NO: 350,
        STATIONNAM: 'CHUNARU GHAT',
        REMARKS: null,
        Station_Na: 'Chunaru ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.50975041851775, 24.175930265092553],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 351,
        SL_NO: 351,
        STATIONNAM: 'AMUROAD',
        REMARKS: null,
        Station_Na: 'Amuroad',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.53292862191275, 24.146499698352258],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 352,
        SL_NO: 352,
        STATIONNAM: 'ASSAMPARA',
        REMARKS: null,
        Station_Na: 'Assampara',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.56450631304831, 24.110347624516262],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 340,
        SL_NO: 340,
        STATIONNAM: 'SATIYAJURI',
        REMARKS: null,
        Station_Na: 'Satiyajuri',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.55654134117135, 24.280914336970696],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 341,
        SL_NO: 341,
        STATIONNAM: 'LASKARPUR',
        REMARKS: null,
        Station_Na: 'Laskarpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.50186904626555, 24.2772538920927],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 339,
        SL_NO: 339,
        STATIONNAM: 'RASHIDPUR',
        REMARKS: null,
        Station_Na: 'Rashidpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.60926028755043, 24.281898719299637],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 338,
        SL_NO: 338,
        STATIONNAM: 'SATGAON',
        REMARKS: null,
        Station_Na: 'Satgaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.64535514326312, 24.284471319902345],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 337,
        SL_NO: 337,
        STATIONNAM: 'SRIMANGAL',
        REMARKS: null,
        Station_Na: 'Srimangal',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.73689267592353, 24.30669018078251],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 336,
        SL_NO: 336,
        STATIONNAM: 'BHANUGACH',
        REMARKS: null,
        Station_Na: 'Bhanugach',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.84265893681355, 24.3516560230603],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 335,
        SL_NO: 335,
        STATIONNAM: 'SHAMSHERNAGAR',
        REMARKS: null,
        Station_Na: 'Shamshernagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.90267162216617, 24.38097367073537],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 334,
        SL_NO: 334,
        STATIONNAM: 'MANU',
        REMARKS: null,
        Station_Na: 'Manu',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.9425964980945, 24.42661071359307],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 332,
        SL_NO: 332,
        STATIONNAM: 'LANGLA',
        REMARKS: null,
        Station_Na: 'Langla',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.98194892349052, 24.453657400842225],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 333,
        SL_NO: 333,
        STATIONNAM: 'TILAGON',
        REMARKS: null,
        Station_Na: 'Tilagon',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.95851122538835, 24.439994889410222],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 330,
        SL_NO: 330,
        STATIONNAM: 'JURI',
        REMARKS: null,
        Station_Na: 'Juri',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.11661501569964, 24.592386343510395],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 329,
        SL_NO: 329,
        STATIONNAM: 'DAKSINBHAG',
        REMARKS: null,
        Station_Na: 'Daksinbhag',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.15527315893067, 24.624536753263026],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 328,
        SL_NO: 328,
        STATIONNAM: 'KATHALTALI',
        REMARKS: null,
        Station_Na: 'Kathaltali',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.17382039967147, 24.66698446826513],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 327,
        SL_NO: 327,
        STATIONNAM: 'BARLEKHA',
        REMARKS: null,
        Station_Na: 'Barlekha',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.19636506184615, 24.707542364935826],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 326,
        SL_NO: 326,
        STATIONNAM: 'MURAUL',
        REMARKS: null,
        Station_Na: 'Muraul',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.2074277017279, 24.744653796813548],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 313,
        SL_NO: 313,
        STATIONNAM: 'CHHATAK BAZAR',
        REMARKS: null,
        Station_Na: 'Chhatak bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.67507947334795, 25.03852475046967],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 317,
        SL_NO: 317,
        STATIONNAM: 'SYLHET',
        REMARKS: null,
        Station_Na: 'Sylhet',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.8598479889857, 24.886533759482603],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 322,
        SL_NO: 322,
        STATIONNAM: 'BARMCHAL',
        REMARKS: null,
        Station_Na: 'Barmchal',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.98433714555965, 24.600465738049948],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 321,
        SL_NO: 321,
        STATIONNAM: 'BHATER BAZAR',
        REMARKS: null,
        Station_Na: 'Bhater bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.99298104275918, 24.631627843860418],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 323,
        SL_NO: 323,
        STATIONNAM: 'CHHAKAPAN',
        REMARKS: null,
        Station_Na: 'Chhakapan',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.00729375221427, 24.565374493577586],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 320,
        SL_NO: 320,
        STATIONNAM: 'MAIJGAON',
        REMARKS: null,
        Station_Na: 'Maijgaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.98104847765431, 24.664613742675726],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 331,
        SL_NO: 331,
        STATIONNAM: 'KULAURA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Kulaura',
      },
      geometry: {
        type: 'Point',
        coordinates: [92.04571517401368, 24.52810468200556],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 318,
        SL_NO: 318,
        STATIONNAM: 'MOGLA BAZAR',
        REMARKS: null,
        Station_Na: 'Mogla bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.90761563074261, 24.81923290815559],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 319,
        SL_NO: 319,
        STATIONNAM: 'FENCHUGANJ',
        REMARKS: null,
        Station_Na: 'Fenchuganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.94259657187378, 24.711837762330894],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 316,
        SL_NO: 316,
        STATIONNAM: 'KHAZANCHGAON',
        REMARKS: null,
        Station_Na: 'Khazanchgaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.75426462879845, 24.879188291975968],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 315,
        SL_NO: 315,
        STATIONNAM: 'SATPUR',
        REMARKS: null,
        Station_Na: 'Satpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.68654631579588, 24.92732637327725],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 314,
        SL_NO: 314,
        STATIONNAM: 'AFZALABAD',
        REMARKS: null,
        Station_Na: 'Afzalabad',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.67034148472355, 24.97730092484554],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 306,
        SL_NO: 306,
        STATIONNAM: 'KALIKA PRASHAD',
        REMARKS: null,
        Station_Na: 'Kalika prashad',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.95542127152083, 24.088583104484083],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 305,
        SL_NO: 305,
        STATIONNAM: 'CHHAYSUTI',
        REMARKS: null,
        Station_Na: 'Chhaysuti',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.92256137094525, 24.128393387692036],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 297,
        SL_NO: 297,
        STATIONNAM: 'KISHOREGANJ',
        REMARKS: null,
        Station_Na: 'Kishoreganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.79219830747162, 24.43415837814057],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 298,
        SL_NO: 298,
        STATIONNAM: 'JASOOALPUR',
        REMARKS: null,
        Station_Na: 'Jasooalpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.81408703200813, 24.39023400973944],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 299,
        SL_NO: 299,
        STATIONNAM: 'GACHIHATA',
        REMARKS: null,
        Station_Na: 'Gachihata',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.830451983363, 24.338796783978612],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 300,
        SL_NO: 300,
        STATIONNAM: 'MANIK KHALI',
        REMARKS: null,
        Station_Na: 'Manik khali',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.84679427483536, 24.30320189212131],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 303,
        SL_NO: 303,
        STATIONNAM: 'BAJITPUR',
        REMARKS: null,
        Station_Na: 'Bajitpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.90910368531952, 24.21113020660933],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 304,
        SL_NO: 304,
        STATIONNAM: 'KULIAR CHAR',
        REMARKS: null,
        Station_Na: 'Kuliar char',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.93158723447618, 24.160314664982636],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 250,
        SL_NO: 250,
        STATIONNAM: 'TANGI',
        REMARKS: 'JUNCTION',
        Station_Na: 'Tangi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.41166680973443, 23.89867206575587],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 260,
        SL_NO: 260,
        STATIONNAM: 'PUBAIL',
        REMARKS: null,
        Station_Na: 'Pubail',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.49167663499014, 23.93477235789131],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 261,
        SL_NO: 261,
        STATIONNAM: 'NALCHATA',
        REMARKS: null,
        Station_Na: 'Nalchata',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.52443721983545, 23.931827687498078],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 263,
        SL_NO: 263,
        STATIONNAM: 'GHORASAL FLAG',
        REMARKS: null,
        Station_Na: 'Ghorasal flag',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.60676554714621, 23.937536033441376],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 264,
        SL_NO: 264,
        STATIONNAM: 'GHORASAL',
        REMARKS: null,
        Station_Na: 'Ghorasal',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.64382948248867, 23.936183820509616],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 265,
        SL_NO: 265,
        STATIONNAM: 'JINARDI',
        REMARKS: null,
        Station_Na: 'Jinardi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.68071719850184, 23.931980395943555],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 266,
        SL_NO: 266,
        STATIONNAM: 'NARSINGHDI',
        REMARKS: null,
        Station_Na: 'Narsinghdi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.71703310359534, 23.93035869899966],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 272,
        SL_NO: 272,
        STATIONNAM: 'DAULATKANDI',
        REMARKS: null,
        Station_Na: 'Daulatkandi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.94147467403324, 24.01915920966678],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 271,
        SL_NO: 271,
        STATIONNAM: 'SRINIDHI',
        REMARKS: null,
        Station_Na: 'Srinidhi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.89890294576426, 24.003213624782653],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 270,
        SL_NO: 270,
        STATIONNAM: 'METHEKADA',
        REMARKS: null,
        Station_Na: 'Methekada',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.83175679540379, 23.97092829033045],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 269,
        SL_NO: 269,
        STATIONNAM: 'HATU BHANGA',
        REMARKS: null,
        Station_Na: 'Hatu bhanga',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.79646309885634, 23.950134523179624],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 268,
        SL_NO: 268,
        STATIONNAM: 'KHANABARI',
        REMARKS: null,
        Station_Na: 'Khanabari',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.78276851388476, 23.940446895385826],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 262,
        SL_NO: 262,
        STATIONNAM: 'ARIKHOLA',
        REMARKS: null,
        Station_Na: 'Arikhola',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.56484251677392, 23.92816898543154],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 273,
        SL_NO: 273,
        STATIONNAM: 'SHAMBHUGANJ',
        REMARKS: null,
        Station_Na: 'Shambhuganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.47083297319465, 24.76015654844454],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 290,
        SL_NO: 290,
        STATIONNAM: 'BAKAI NAGAR',
        REMARKS: null,
        Station_Na: 'Bakai nagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.58497641614875, 24.72467037423222],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 291,
        SL_NO: 291,
        STATIONNAM: 'ISHWARGANJ',
        REMARKS: null,
        Station_Na: 'Ishwarganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.61115277582802, 24.688264892733653],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 292,
        SL_NO: 292,
        STATIONNAM: 'SHOHAGI',
        REMARKS: null,
        Station_Na: 'Shohagi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.66210947774005, 24.680846997523155],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 293,
        SL_NO: 293,
        STATIONNAM: 'ATAWARABARI',
        REMARKS: null,
        Station_Na: 'Atawarabari',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.7245405285792, 24.63044147205387],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 294,
        SL_NO: 294,
        STATIONNAM: 'NANDAIL ROAD',
        REMARKS: null,
        Station_Na: 'Nandail road',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.74711628430249, 24.586984692454116],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 295,
        SL_NO: 295,
        STATIONNAM: 'MUSULI',
        REMARKS: null,
        Station_Na: 'Musuli',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.77592459278243, 24.541471729640897],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 296,
        SL_NO: 296,
        STATIONNAM: 'NILGANJ',
        REMARKS: null,
        Station_Na: 'Nilganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.79483764735822, 24.48656854169949],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 233,
        SL_NO: 233,
        STATIONNAM: 'MYMENSINGH',
        REMARKS: 'JUNCTION',
        Station_Na: 'Mymensingh',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.43202218474991, 24.73194495607633],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 226,
        SL_NO: 226,
        STATIONNAM: 'NANDINA',
        REMARKS: null,
        Station_Na: 'Nandina',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.03078461155255, 24.86918817607712],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 227,
        SL_NO: 227,
        STATIONNAM: 'NARUNDI',
        REMARKS: null,
        Station_Na: 'Narundi',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.10369878211512, 24.865741779797276],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 228,
        SL_NO: 228,
        STATIONNAM: 'PIYARPUR',
        REMARKS: null,
        Station_Na: 'Piyarpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.19815049674591, 24.881583960155186],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 232,
        SL_NO: 232,
        STATIONNAM: 'MYMENSINGH ROAD',
        REMARKS: null,
        Station_Na: 'Mymensingh road',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.36949171271752, 24.774942332224196],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 231,
        SL_NO: 231,
        STATIONNAM: 'BAGANBARI',
        REMARKS: null,
        Station_Na: 'Baganbari',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.30197911376507, 24.806489837273396],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 230,
        SL_NO: 230,
        STATIONNAM: 'BIDYAGAON',
        REMARKS: null,
        Station_Na: 'Bidyagaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.25641629470783, 24.824895902591404],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 212,
        SL_NO: 212,
        STATIONNAM: 'BAHADURABAD GHAT',
        REMARKS: null,
        Station_Na: 'Bahadurabad Ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.7341737233842, 25.165566677910594],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 213,
        SL_NO: 213,
        STATIONNAM: 'DEWANGANJ BAZAR',
        REMARKS: null,
        Station_Na: 'Dewanganj Bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.7566986083563, 25.155973434429985],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 214,
        SL_NO: 214,
        STATIONNAM: 'MUSHARRAFGANJ',
        REMARKS: null,
        Station_Na: 'Musharrafganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.78964243742939, 25.086038500051966],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 215,
        SL_NO: 215,
        STATIONNAM: 'ISLAMPUR',
        REMARKS: null,
        Station_Na: 'Islampur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.7922690858882, 25.077608631337025],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 216,
        SL_NO: 216,
        STATIONNAM: 'DURMUT',
        REMARKS: null,
        Station_Na: 'Durmut',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.81820665678488, 25.03855131670488],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 217,
        SL_NO: 217,
        STATIONNAM: 'MELANDAH  BAZAR',
        REMARKS: null,
        Station_Na: 'Melandah Bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.85357589727381, 24.978375381958077],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 218,
        SL_NO: 218,
        STATIONNAM: 'JAMALPUR COURT',
        REMARKS: null,
        Station_Na: 'Jamalpur Court',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.91286474778968, 24.944188868534987],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 225,
        SL_NO: 225,
        STATIONNAM: 'JAGANNATGANJ GHAT',
        REMARKS: null,
        Station_Na: 'Jagannatganj Ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.80061342656988, 24.64722996179688],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 224,
        SL_NO: 224,
        STATIONNAM: 'BORA',
        REMARKS: null,
        Station_Na: 'Bora',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.83104701601886, 24.692243603200748],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 223,
        SL_NO: 223,
        STATIONNAM: 'SARISABARI',
        REMARKS: null,
        Station_Na: 'Sarisabari',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.83924879714218, 24.74411747447776],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 222,
        SL_NO: 222,
        STATIONNAM: 'BAUSI',
        REMARKS: null,
        Station_Na: 'Bausi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.84963987084312, 24.768136989113437],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 221,
        SL_NO: 221,
        STATIONNAM: 'JAFARSHAHEE',
        REMARKS: null,
        Station_Na: 'Jafarshahee',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.89245618436874, 24.84420202589918],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 220,
        SL_NO: 220,
        STATIONNAM: 'KENDUABAZAR',
        REMARKS: null,
        Station_Na: 'Kendua Bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.90650305598032, 24.856562190722936],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 259,
        SL_NO: 259,
        STATIONNAM: 'NARAYANGANJ',
        REMARKS: null,
        Station_Na: 'Narayanganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.50524138100916, 23.601593095871237],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 255,
        SL_NO: 255,
        STATIONNAM: 'DHAKA',
        REMARKS: null,
        Station_Na: 'Dhaka',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.42981703663337, 23.722841493918114],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 256,
        SL_NO: 256,
        STATIONNAM: 'GANDARIA',
        REMARKS: null,
        Station_Na: 'Gandaria',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.43316626661635, 23.69914633514122],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 258,
        SL_NO: 258,
        STATIONNAM: 'CHASHARA',
        REMARKS: null,
        Station_Na: 'Chashara',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.50308225914091, 23.62181274579266],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 257,
        SL_NO: 257,
        STATIONNAM: 'FATULLA',
        REMARKS: null,
        Station_Na: 'Fatulla',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.47447964256615, 23.653794998787482],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 254,
        SL_NO: 254,
        STATIONNAM: 'TEJGAON',
        REMARKS: null,
        Station_Na: 'Tejgaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.39762873301245, 23.75866716812828],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 253,
        SL_NO: 253,
        STATIONNAM: 'BANANI',
        REMARKS: null,
        Station_Na: 'Banani',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.40329752811753, 23.793333302747108],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 252,
        SL_NO: 252,
        STATIONNAM: 'DHAKA CANT',
        REMARKS: null,
        Station_Na: 'Dhaka cant',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.41921222085898, 23.818994245919757],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 251,
        SL_NO: 251,
        STATIONNAM: 'BIMAN BANDAR',
        REMARKS: null,
        Station_Na: 'Biman bandar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.41178139940085, 23.850851057683983],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 249,
        SL_NO: 249,
        STATIONNAM: 'DHIRASRAM',
        REMARKS: null,
        Station_Na: 'Dhirasram',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.41505420346813, 23.929088571697996],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 248,
        SL_NO: 248,
        STATIONNAM: 'JOYDEBPUR',
        REMARKS: null,
        Station_Na: 'Joydebpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.4206158641766, 23.971100111627404],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 247,
        SL_NO: 247,
        STATIONNAM: 'BHAWALGAZIPUR',
        REMARKS: null,
        Station_Na: 'Bhawalgazipur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.42843601214396, 24.019302606946443],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 246,
        SL_NO: 246,
        STATIONNAM: 'RAJENDRAPUR',
        REMARKS: null,
        Station_Na: 'Rajendrapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.44436617927012, 24.071004949437658],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 242,
        SL_NO: 242,
        STATIONNAM: 'KAORAID',
        REMARKS: null,
        Station_Na: 'Kaoraid',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.51014735575332, 24.295351260727934],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 244,
        SL_NO: 244,
        STATIONNAM: 'SRIPUR',
        REMARKS: null,
        Station_Na: 'Sripur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.47886658157796, 24.18760859026294],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 245,
        SL_NO: 245,
        STATIONNAM: 'IZZATPUR',
        REMARKS: null,
        Station_Na: 'Izzatpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.46512632497553, 24.140987492324527],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 237,
        SL_NO: 237,
        STATIONNAM: 'AHMEDBARI',
        REMARKS: null,
        Station_Na: 'Ahmedbari',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.50618714746366, 24.587495542665792],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 238,
        SL_NO: 238,
        STATIONNAM: 'AULIANAGAR',
        REMARKS: null,
        Station_Na: 'Aulianagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.50966640016082, 24.545276817043],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 240,
        SL_NO: 240,
        STATIONNAM: 'GAFFARGAON',
        REMARKS: null,
        Station_Na: 'Gaffargaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.54827893189187, 24.414814008201787],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 241,
        SL_NO: 241,
        STATIONNAM: 'MOSHAKHALI',
        REMARKS: null,
        Station_Na: 'Moshakhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.53770467818609, 24.380474098732208],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 382,
        SL_NO: 382,
        STATIONNAM: 'CHITOSI ROAD',
        REMARKS: null,
        Station_Na: 'Chitosi road',
      },
      geometry: {
        type: 'Point',
        coordinates: [91.02622214319005, 23.236211804718533],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 391,
        SL_NO: 391,
        STATIONNAM: 'CHANDPUR',
        REMARKS: null,
        Station_Na: 'Chandpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.6534652039088, 23.224203022168695],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 388,
        SL_NO: 388,
        STATIONNAM: 'MADHUROAD',
        REMARKS: null,
        Station_Na: 'Madhuroad',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.76344279815689, 23.244504692696342],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 389,
        SL_NO: 389,
        STATIONNAM: 'SHAHATALI',
        REMARKS: null,
        Station_Na: 'Shahatali',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.73105641060043, 23.241062274496695],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 385,
        SL_NO: 385,
        STATIONNAM: 'DAULATGANJ',
        REMARKS: null,
        Station_Na: 'Daulatganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.8804245446922, 23.2574651382071],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 386,
        SL_NO: 386,
        STATIONNAM: 'WARUK',
        REMARKS: null,
        Station_Na: 'Waruk',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.83419046854651, 23.257955795564506],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 383,
        SL_NO: 383,
        STATIONNAM: 'SHAHRASTA ROAD',
        REMARKS: null,
        Station_Na: 'Shahrasta road',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.97026826098542, 23.24252344784463],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 144,
        SL_NO: 144,
        STATIONNAM: 'SIRAJGANJ GHAT',
        REMARKS: null,
        Station_Na: 'Sirajganj Ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.70938122436881, 24.477073800804497],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 143,
        SL_NO: 143,
        STATIONNAM: 'SIRAJGANJ BAHIRGOLA',
        REMARKS: null,
        Station_Na: 'Sirajganj Bazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.70477152030053, 24.451530459689327],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 142,
        SL_NO: 142,
        STATIONNAM: 'B.B/WEST',
        REMARKS: null,
        Station_Na: 'B.b/West',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.73010248019384, 24.39497538791494],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 141,
        SL_NO: 141,
        STATIONNAM: 'SIRAJGANJ RAIPUR',
        REMARKS: null,
        Station_Na: 'Sirajganj Raipur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.69205088332943, 24.42738296659325],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 129,
        SL_NO: 129,
        STATIONNAM: 'GAFURABAD',
        REMARKS: null,
        Station_Na: 'Gafurabad',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.21205900092822, 24.174038170799037],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 135,
        SL_NO: 135,
        STATIONNAM: 'DILPASHAR',
        REMARKS: null,
        Station_Na: 'Dilpashar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.4421386121096, 24.23467074215041],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 134,
        SL_NO: 134,
        STATIONNAM: 'SARATNAGAR',
        REMARKS: null,
        Station_Na: 'Saratnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.41651905663936, 24.223972192865155],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 127,
        SL_NO: 127,
        STATIONNAM: 'MAJHGRAM',
        REMARKS: null,
        Station_Na: 'Majhgram',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.10065463745413, 24.160917398622427],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 128,
        SL_NO: 128,
        STATIONNAM: 'MOLLADULLA',
        REMARKS: null,
        Station_Na: 'Molladulla',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.14286812255469, 24.1614816124761],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 130,
        SL_NO: 130,
        STATIONNAM: 'CHATMOHOR',
        REMARKS: null,
        Station_Na: 'Chatmohor',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.27870940938833, 24.187431123371706],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 133,
        SL_NO: 133,
        STATIONNAM: 'BORAL BRIDGE',
        REMARKS: null,
        Station_Na: 'Boral bridge',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.39978021265888, 24.21743418761801],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 132,
        SL_NO: 132,
        STATIONNAM: 'BHANGOORA',
        REMARKS: null,
        Station_Na: 'Bhangoora',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.38492592354973, 24.21394370132965],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 131,
        SL_NO: 131,
        STATIONNAM: 'GOOAKHORA',
        REMARKS: null,
        Station_Na: 'Gooakhora',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.32493576572439, 24.19887753579275],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 136,
        SL_NO: 136,
        STATIONNAM: 'LAHIRIMOHONPUR',
        REMARKS: null,
        Station_Na: 'Lahirimohonpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.50116744881134, 24.259378250147154],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 137,
        SL_NO: 137,
        STATIONNAM: 'ULLAPARA',
        REMARKS: null,
        Station_Na: 'Ullapara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.57251939694152, 24.29083640048461],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 138,
        SL_NO: 138,
        STATIONNAM: 'SALOP',
        REMARKS: null,
        Station_Na: 'Salop',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.61214377524973, 24.316058608696846],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 139,
        SL_NO: 139,
        STATIONNAM: 'JAMTOIL',
        REMARKS: null,
        Station_Na: 'Jamtoil',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.66009999994981, 24.361079999981047],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 84,
        SL_NO: 84,
        STATIONNAM: 'PAKSEY',
        REMARKS: null,
        Station_Na: 'Paksey',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.04390729386279, 24.069006166169412],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 85,
        SL_NO: 85,
        STATIONNAM: 'BHAIRAMARA',
        REMARKS: null,
        Station_Na: 'Bhairamara',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.99495695115856, 24.023706215396597],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 87,
        SL_NO: 87,
        STATIONNAM: 'RAITA',
        REMARKS: null,
        Station_Na: 'Raita',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.92753586339552, 24.099901310006192],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 88,
        SL_NO: 88,
        STATIONNAM: 'GOLBATHEN',
        REMARKS: null,
        Station_Na: 'Golbathen',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.99765005690665, 23.985061602814955],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 89,
        SL_NO: 89,
        STATIONNAM: 'MIRPUR',
        REMARKS: null,
        Station_Na: 'Mirpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.00354010341044, 23.933664154358407],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 100,
        SL_NO: 100,
        STATIONNAM: 'JAGATI',
        REMARKS: null,
        Station_Na: 'Jagati',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.08997341145049, 23.8843271273247],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 101,
        SL_NO: 101,
        STATIONNAM: 'KUSTIA COURT',
        REMARKS: null,
        Station_Na: 'Kustia court',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.12675484991313, 23.907167295011796],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 102,
        SL_NO: 102,
        STATIONNAM: 'KUSTIA',
        REMARKS: null,
        Station_Na: 'Kustia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.14820110025109, 23.90080277997864],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 103,
        SL_NO: 103,
        STATIONNAM: 'CHARAIKHOLE',
        REMARKS: null,
        Station_Na: 'Charaikhole',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.20766437290611, 23.87701974671314],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 104,
        SL_NO: 104,
        STATIONNAM: 'KUMARKHALI',
        REMARKS: null,
        Station_Na: 'Kumarkhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.24662775176279, 23.85815797614607],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 105,
        SL_NO: 105,
        STATIONNAM: 'KHOKSA',
        REMARKS: null,
        Station_Na: 'Khoksa',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.33303085146181, 23.816782061467993],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 106,
        SL_NO: 106,
        STATIONNAM: 'MACHHPARA',
        REMARKS: null,
        Station_Na: 'Machhpara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.37515246907851, 23.796476182933745],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 109,
        SL_NO: 109,
        STATIONNAM: 'RAMDIA',
        REMARKS: null,
        Station_Na: 'Ramdia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.52584844650184, 23.71217355617684],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 110,
        SL_NO: 110,
        STATIONNAM: 'BAHARPUR',
        REMARKS: null,
        Station_Na: 'Baharpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5744780804718, 23.680324608303756],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 111,
        SL_NO: 111,
        STATIONNAM: 'ARKANDI',
        REMARKS: null,
        Station_Na: 'Arkandi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5904694687646, 23.629389059053363],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 91,
        SL_NO: 91,
        STATIONNAM: 'HALSHA',
        REMARKS: null,
        Station_Na: 'Halsha',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.00884254081213, 23.832628246968405],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 92,
        SL_NO: 92,
        STATIONNAM: 'ALAMDANGA',
        REMARKS: null,
        Station_Na: 'Alamdanga',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.94261931004195, 23.76230406448544],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 94,
        SL_NO: 94,
        STATIONNAM: 'MOMINPUR',
        REMARKS: null,
        Station_Na: 'Mominpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.8850327364454, 23.687997660074135],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 95,
        SL_NO: 95,
        STATIONNAM: 'CHUADANGA',
        REMARKS: null,
        Station_Na: 'Chuadanga',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.86492931577209, 23.644805924994024],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 96,
        SL_NO: 96,
        STATIONNAM: 'JAYRAMPUR',
        REMARKS: null,
        Station_Na: 'Jayrampur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.81979382881994, 23.591467128202126],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 464,
        SL_NO: 464,
        STATIONNAM: 'UTHALI',
        REMARKS: null,
        Station_Na: 'Uthali',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.84851072798857, 23.490335746661774],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 112,
        SL_NO: 112,
        STATIONNAM: 'NALIA',
        REMARKS: null,
        Station_Na: 'Nalia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.63414014520363, 23.571220520970243],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 456,
        SL_NO: 456,
        STATIONNAM: 'GHOAKHALI',
        REMARKS: null,
        Station_Na: 'Ghoakhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.64660654778537, 23.471765622322756],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 457,
        SL_NO: 457,
        STATIONNAM: 'SATAIR',
        REMARKS: null,
        Station_Na: 'Satair',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.67837519519217, 23.424299251361756],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 462,
        SL_NO: 462,
        STATIONNAM: 'BHATIAPARA GHAT',
        REMARKS: null,
        Station_Na: 'Bhatiapara ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.69987478391663, 23.21305451409997],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 466,
        SL_NO: 466,
        STATIONNAM: 'SAFDARPUR',
        REMARKS: null,
        Station_Na: 'Safdarpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.9676056863634, 23.45952974951692],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 467,
        SL_NO: 467,
        STATIONNAM: 'KOTCHANDPUR',
        REMARKS: null,
        Station_Na: 'Kotchandpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.01572407719603, 23.414359796106147],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 468,
        SL_NO: 468,
        STATIONNAM: 'SUNDARPUR',
        REMARKS: null,
        Station_Na: 'Sundarpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.08924117520881, 23.413797362611163],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 469,
        SL_NO: 469,
        STATIONNAM: 'MUBARAKGANJ',
        REMARKS: null,
        Station_Na: 'Mubarakganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.13522324988392, 23.388986707794],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 470,
        SL_NO: 470,
        STATIONNAM: 'BAROBAZAR',
        REMARKS: null,
        Station_Na: 'Barobazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.15608987987376, 23.301051859950736],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 471,
        SL_NO: 471,
        STATIONNAM: 'MEHERULLANAGAR',
        REMARKS: null,
        Station_Na: 'Meherullanagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.17713928248912, 23.201774423804714],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 474,
        SL_NO: 474,
        STATIONNAM: 'BENAPOL',
        REMARKS: null,
        Station_Na: 'Benapol',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.89145671646457, 23.035257463935114],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 490,
        SL_NO: 490,
        STATIONNAM: 'KHULNA',
        REMARKS: null,
        Station_Na: 'Khulna',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5773239403615, 22.803258834679117],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 1,
        SL_NO: 1,
        STATIONNAM: 'PACHAGARH',
        REMARKS: null,
        Station_Na: 'Pachagarh',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.54797357405063, 26.33198346696899],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 2,
        SL_NO: 2,
        STATIONNAM: 'NAYANIPUR',
        REMARKS: null,
        Station_Na: 'Nayanipur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.50737755383274, 26.288145203747543],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 3,
        SL_NO: 3,
        STATIONNAM: 'KISMAT',
        REMARKS: null,
        Station_Na: 'Kismat',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.44606022904318, 26.214317097885427],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 4,
        SL_NO: 4,
        STATIONNAM: 'ROHEA',
        REMARKS: null,
        Station_Na: 'Rohea',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.39341725566065, 26.140003125202462],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 5,
        SL_NO: 5,
        STATIONNAM: 'AKHANAGAR',
        REMARKS: null,
        Station_Na: 'Akhanagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.39371477555443, 26.102577059461243],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 6,
        SL_NO: 6,
        STATIONNAM: 'THAKURGAON ROAD',
        REMARKS: null,
        Station_Na: 'Thakurgaon road',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.41827378129028, 26.010204126413004],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 7,
        SL_NO: 7,
        STATIONNAM: 'SHIBGANJ',
        REMARKS: null,
        Station_Na: 'Shibganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.38068388244183, 25.95099040916681],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 8,
        SL_NO: 8,
        STATIONNAM: 'BHOMRADAHA',
        REMARKS: null,
        Station_Na: 'Bhomradaha',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.36269393366916, 25.909746200096127],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 9,
        SL_NO: 9,
        STATIONNAM: 'PIRGANJ',
        REMARKS: null,
        Station_Na: 'Pirganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.35999308825224, 25.857488881431838],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 10,
        SL_NO: 10,
        STATIONNAM: 'SULTANPUR SCHOOL',
        REMARKS: null,
        Station_Na: 'Sultanpur school',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.39266218013229, 25.822452797384326],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 11,
        SL_NO: 11,
        STATIONNAM: 'SETABGANJ',
        REMARKS: null,
        Station_Na: 'Setabganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.42368317903384, 25.814750797941308],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 12,
        SL_NO: 12,
        STATIONNAM: 'MOLLAPARA',
        REMARKS: null,
        Station_Na: 'Mollapara',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.48205574941447, 25.78309415073814],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 13,
        SL_NO: 13,
        STATIONNAM: 'MUNGALPUR',
        REMARKS: null,
        Station_Na: 'Mungalpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.51671612206005, 25.749212207978083],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 14,
        SL_NO: 14,
        STATIONNAM: 'BAJNAHAR',
        REMARKS: null,
        Station_Na: 'Bajnahar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.56761157911562, 25.67988415881233],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 16,
        SL_NO: 16,
        STATIONNAM: 'BIRAL',
        REMARKS: null,
        Station_Na: 'Biral',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.51132202965609, 25.637151922792558],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 15,
        SL_NO: 15,
        STATIONNAM: 'KANCHON',
        REMARKS: null,
        Station_Na: 'Kanchon',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.59159837185307, 25.62268660755656],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 18,
        SL_NO: 18,
        STATIONNAM: 'DINAJPUR',
        REMARKS: null,
        Station_Na: 'Dinajpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.64536288469974, 25.620788549085393],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 19,
        SL_NO: 19,
        STATIONNAM: 'KAUGAON',
        REMARKS: null,
        Station_Na: 'Kaugaon',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.72438054121415, 25.635515001978348],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 20,
        SL_NO: 20,
        STATIONNAM: 'CHIRIRBADAR',
        REMARKS: null,
        Station_Na: 'Chirirbadar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.78356179471736, 25.64550232796399],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 21,
        SL_NO: 21,
        STATIONNAM: 'MANMATHAPUR',
        REMARKS: null,
        Station_Na: 'Manmathapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.86237325697113, 25.656847150272174],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 33,
        SL_NO: 33,
        STATIONNAM: 'PARBATIPUR',
        REMARKS: 'JUNCTION',
        Station_Na: 'Parbatipur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.91760263369736, 25.656638918700526],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 32,
        SL_NO: 32,
        STATIONNAM: 'BELAICHANDI',
        REMARKS: null,
        Station_Na: 'Belaichandi',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.90895073008463, 25.727144466402727],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 31,
        SL_NO: 31,
        STATIONNAM: 'SAIDPUR',
        REMARKS: null,
        Station_Na: 'Saidpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.89923096972386, 25.779945239598522],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 30,
        SL_NO: 30,
        STATIONNAM: 'KHIRATNAGAR',
        REMARKS: null,
        Station_Na: 'Khiratnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.85757433381526, 25.845934040650775],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 29,
        SL_NO: 29,
        STATIONNAM: 'DAROANI',
        REMARKS: null,
        Station_Na: 'Daroani',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.84458924067653, 25.895090178328665],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 28,
        SL_NO: 28,
        STATIONNAM: 'NILPHAMARI COLLEGE',
        REMARKS: null,
        Station_Na: 'Nilphamari college',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.84015656198287, 25.93820361473046],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 27,
        SL_NO: 27,
        STATIONNAM: 'NILPHAMARI',
        REMARKS: null,
        Station_Na: 'Nilphamari',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.8319091096257, 25.9903543481648],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 26,
        SL_NO: 26,
        STATIONNAM: 'TARANIBARI',
        REMARKS: null,
        Station_Na: 'Taranibari',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.8254547967333, 26.030660670865704],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 25,
        SL_NO: 25,
        STATIONNAM: 'DOMAR',
        REMARKS: null,
        Station_Na: 'Domar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.82265481091363, 26.095050642712863],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 24,
        SL_NO: 24,
        STATIONNAM: 'MIRJAGANJ',
        REMARKS: null,
        Station_Na: 'Mirjaganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.80291733150536, 26.185697801939476],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 23,
        SL_NO: 23,
        STATIONNAM: 'CHILHATI',
        REMARKS: null,
        Station_Na: 'Chilhati',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.79871377868153, 26.243803094963873],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 187,
        SL_NO: 187,
        STATIONNAM: 'KHOLAHAT',
        REMARKS: null,
        Station_Na: 'Kholahat',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.97776790766234, 25.653625292950963],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 186,
        SL_NO: 186,
        STATIONNAM: 'BADARGANJ',
        REMARKS: null,
        Station_Na: 'Badarganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.05006421030724, 25.662050113307018],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 185,
        SL_NO: 185,
        STATIONNAM: 'AULIAGANJ',
        REMARKS: null,
        Station_Na: 'Auliaganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.1349106207102, 25.686920341017508],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 184,
        SL_NO: 184,
        STATIONNAM: 'SHAMPUR',
        REMARKS: null,
        Station_Na: 'Shampur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.19723499513475, 25.70948212964035],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 183,
        SL_NO: 183,
        STATIONNAM: 'RANGPUR',
        REMARKS: null,
        Station_Na: 'Rangpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.26746379568246, 25.72809203307081],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 182,
        SL_NO: 182,
        STATIONNAM: 'MIRBAGH',
        REMARKS: null,
        Station_Na: 'Mirbagh',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.3421554009504, 25.75762191707703],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 181,
        SL_NO: 181,
        STATIONNAM: 'KAUNIA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Kaunia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.41992174719957, 25.773809323534675],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 188,
        SL_NO: 188,
        STATIONNAM: 'TISTA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Tista',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.44439683226159, 25.809124007155464],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 189,
        SL_NO: 189,
        STATIONNAM: 'SIGIRDABIRHAT',
        REMARKS: null,
        Station_Na: 'Sigirdabirhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.49571994466585, 25.80474095867446],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 190,
        SL_NO: 190,
        STATIONNAM: 'RAJAPURHAT',
        REMARKS: null,
        Station_Na: 'Rajapurhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.54073341781367, 25.795486233634055],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 191,
        SL_NO: 191,
        STATIONNAM: 'TOGRAHAT',
        REMARKS: null,
        Station_Na: 'Tograhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.60081475848767, 25.806941909889265],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 192,
        SL_NO: 192,
        STATIONNAM: 'KURIGRAM',
        REMARKS: 'JUNCTION',
        Station_Na: 'Kurigram',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.64345545179563, 25.80534928104706],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 193,
        SL_NO: 193,
        STATIONNAM: 'KURIGRAM',
        REMARKS: null,
        Station_Na: 'Kurigram',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.63303390658432, 25.75209597292434],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 194,
        SL_NO: 194,
        STATIONNAM: 'PANCHPIR',
        REMARKS: null,
        Station_Na: 'Panchpir',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.62136847843611, 25.702676535329967],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 195,
        SL_NO: 195,
        STATIONNAM: 'WALIPUR',
        REMARKS: null,
        Station_Na: 'Walipur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.63166809911682, 25.642507357655468],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 196,
        SL_NO: 196,
        STATIONNAM: 'BALABARI',
        REMARKS: null,
        Station_Na: 'Balabari',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.65574644427683, 25.600195102494297],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 197,
        SL_NO: 197,
        STATIONNAM: 'CHILMARIBANDAR',
        REMARKS: null,
        Station_Na: 'Chilmaribandar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.68721004016678, 25.566413676444427],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 198,
        SL_NO: 198,
        STATIONNAM: 'MOHENDRANAGAR',
        REMARKS: null,
        Station_Na: 'Mohendranagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.45149997782704, 25.874601179756237],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 199,
        SL_NO: 199,
        STATIONNAM: 'LALMANIRHAT',
        REMARKS: 'JUNCTION',
        Station_Na: 'Lalmanirhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.44809727802385, 25.914274303413688],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 200,
        SL_NO: 200,
        STATIONNAM: 'MOGLAHAT',
        REMARKS: null,
        Station_Na: 'Moglahat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.45510101346379, 25.98838040207993],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 201,
        SL_NO: 201,
        STATIONNAM: 'RAISHBAG',
        REMARKS: null,
        Station_Na: 'Raishbag',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.38025671955207, 25.917522614674965],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 202,
        SL_NO: 202,
        STATIONNAM: 'ADITMARI',
        REMARKS: null,
        Station_Na: 'Aditmari',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.34726708130228, 25.92408009896352],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 203,
        SL_NO: 203,
        STATIONNAM: 'KAUNIA',
        REMARKS: null,
        Station_Na: 'Kaunia',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.27838140662371, 25.94804738234916],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 204,
        SL_NO: 204,
        STATIONNAM: 'TUSHBADAR',
        REMARKS: null,
        Station_Na: 'Tushbadar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.19241347895735, 25.97400463478432],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 205,
        SL_NO: 205,
        STATIONNAM: 'BHOTEMARI',
        REMARKS: null,
        Station_Na: 'Bhotemari',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.17983235752513, 25.994323982963753],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 206,
        SL_NO: 206,
        STATIONNAM: 'HATIBANDA',
        REMARKS: null,
        Station_Na: 'Hatibanda',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.1457520839861, 26.112850130227102],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 207,
        SL_NO: 207,
        STATIONNAM: 'BARAKHATA',
        REMARKS: null,
        Station_Na: 'Barakhata',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.08902747719767, 26.23509986222804],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 208,
        SL_NO: 208,
        STATIONNAM: 'BAURA',
        REMARKS: null,
        Station_Na: 'Baura',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.06886290792258, 26.2775133213303],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 209,
        SL_NO: 209,
        STATIONNAM: 'PATGRAM',
        REMARKS: null,
        Station_Na: 'Patgram',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.99665056181519, 26.359592226475424],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 180,
        SL_NO: 180,
        STATIONNAM: 'ANNANDANAGAR',
        REMARKS: null,
        Station_Na: 'Annandanagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.41125479416942, 25.715053336681404],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 179,
        SL_NO: 179,
        STATIONNAM: 'PIRGACHA',
        REMARKS: null,
        Station_Na: 'Pirgacha',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.41244505134506, 25.645483093686646],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 178,
        SL_NO: 178,
        STATIONNAM: 'CHOWDHURANI',
        REMARKS: null,
        Station_Na: 'Chowdhurani',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.42852789400771, 25.5864469892386],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 177,
        SL_NO: 177,
        STATIONNAM: 'HASANGANJ',
        REMARKS: null,
        Station_Na: 'Hasanganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.43411268616046, 25.56590295291223],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 176,
        SL_NO: 176,
        STATIONNAM: 'BAMANDANGA',
        REMARKS: null,
        Station_Na: 'Bamandanga',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.45069883624664, 25.52844794077112],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 175,
        SL_NO: 175,
        STATIONNAM: 'NALDANGA',
        REMARKS: null,
        Station_Na: 'Naldanga',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.48304745006799, 25.4690625652821],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 174,
        SL_NO: 174,
        STATIONNAM: 'KAMARPARA',
        REMARKS: null,
        Station_Na: 'Kamarpara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.51387030112232, 25.411010949474594],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 173,
        SL_NO: 173,
        STATIONNAM: 'KUPTALA',
        REMARKS: null,
        Station_Na: 'Kuptala',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5358199747278, 25.360389881579263],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 172,
        SL_NO: 172,
        STATIONNAM: 'GAIBANDHA',
        REMARKS: null,
        Station_Na: 'Gaibandha',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5421714090772, 25.33265853399717],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 168,
        SL_NO: 168,
        STATIONNAM: 'TRIMOHONI',
        REMARKS: null,
        Station_Na: 'Trimohoni',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.54277377857757, 25.283281048670002],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 170,
        SL_NO: 170,
        STATIONNAM: 'KANCHIPARA',
        REMARKS: null,
        Station_Na: 'Kanchipara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.61106630361203, 25.309015235501377],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 171,
        SL_NO: 171,
        STATIONNAM: 'BALASHIGHAT',
        REMARKS: null,
        Station_Na: 'Balashi Ghat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.61808085873223, 25.310709154337456],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 167,
        SL_NO: 167,
        STATIONNAM: 'BADIAKHALROAD',
        REMARKS: null,
        Station_Na: 'Badiakhal Road',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.54261983924987, 25.23575602793691],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 161,
        SL_NO: 161,
        STATIONNAM: 'BONARPARA',
        REMARKS: 'JUNCTION',
        Station_Na: 'Bonarpara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.52347814158018, 25.16581205591661],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 162,
        SL_NO: 162,
        STATIONNAM: 'BHARATKHALI',
        REMARKS: null,
        Station_Na: 'Bharatkhali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.5731595731631, 25.188732881208594],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 166,
        SL_NO: 166,
        STATIONNAM: 'PHULCHARI / TISTAMUKH',
        REMARKS: null,
        Station_Na: 'Phulchari / Tistamukh',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.59722124547207, 25.18706897080889],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 160,
        SL_NO: 160,
        STATIONNAM: 'MAHIMAGANJ',
        REMARKS: null,
        Station_Na: 'Mahimaganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.49521636095594, 25.097961459621807],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 159,
        SL_NO: 159,
        STATIONNAM: 'SONATALA',
        REMARKS: null,
        Station_Na: 'Sonatala',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.49764360218758, 25.03941104874328],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 158,
        SL_NO: 158,
        STATIONNAM: 'VELURPARA',
        REMARKS: null,
        Station_Na: 'Velurpara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.50145585733127, 24.98214205222062],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 157,
        SL_NO: 157,
        STATIONNAM: 'SYED AHMED COLLEGE',
        REMARKS: null,
        Station_Na: 'Syed Ahmed College',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.49620657417346, 24.957071444310433],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 156,
        SL_NO: 156,
        STATIONNAM: 'SUKANPUKUR',
        REMARKS: null,
        Station_Na: 'Sukanpukur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.4819588598687, 24.929662558797407],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 155,
        SL_NO: 155,
        STATIONNAM: 'GABTALI',
        REMARKS: null,
        Station_Na: 'Gabtali',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.45184039517821, 24.8854491463854],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 154,
        SL_NO: 154,
        STATIONNAM: 'BOGRA',
        REMARKS: null,
        Station_Na: 'Bogra',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.36901155995118, 24.849304265742354],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 153,
        SL_NO: 153,
        STATIONNAM: 'KAHALOO',
        REMARKS: null,
        Station_Na: 'Kahaloo',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.28260807307093, 24.83247573683381],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 152,
        SL_NO: 152,
        STATIONNAM: 'PACHPIR MAZAR',
        REMARKS: null,
        Station_Na: 'Pachpir mazar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.24031062805258, 24.826850625192147],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 151,
        SL_NO: 151,
        STATIONNAM: 'TALORA',
        REMARKS: null,
        Station_Na: 'Talora',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.189811862708, 24.82295427572418],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 150,
        SL_NO: 150,
        STATIONNAM: 'ALTAFNAGAR',
        REMARKS: null,
        Station_Na: 'Altafnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.13346085371668, 24.816986340844323],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 149,
        SL_NO: 149,
        STATIONNAM: 'NASARATPUR',
        REMARKS: null,
        Station_Na: 'Nasaratpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.09767149900595, 24.813834974019173],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 148,
        SL_NO: 148,
        STATIONNAM: 'ADAMDIGHI',
        REMARKS: null,
        Station_Na: 'Adamdighi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.06019593187447, 24.808738555876072],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 48,
        SL_NO: 48,
        STATIONNAM: 'SANTAHAR',
        REMARKS: 'JUNCTION',
        Station_Na: 'Santahar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.99076069764466, 24.8172779669464],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 47,
        SL_NO: 47,
        STATIONNAM: 'CHHATIANAGAR',
        REMARKS: null,
        Station_Na: 'Chhatianagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.00007645065709, 24.85193807944975],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 46,
        SL_NO: 46,
        STATIONNAM: 'TILAKPUR',
        REMARKS: null,
        Station_Na: 'Tilakpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.01213823611435, 24.899728510541234],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 45,
        SL_NO: 45,
        STATIONNAM: 'JAFARPUR',
        REMARKS: null,
        Station_Na: 'Jafarpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.0195235862791, 24.962150352860444],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 44,
        SL_NO: 44,
        STATIONNAM: 'AKKELPUR',
        REMARKS: null,
        Station_Na: 'Akkelpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.02677143106398, 25.059946336198024],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 43,
        SL_NO: 43,
        STATIONNAM: 'JAMALGANJ',
        REMARKS: null,
        Station_Na: 'Jamalganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.02812196780992, 25.08198350752913],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 42,
        SL_NO: 42,
        STATIONNAM: 'JAYPURHAT',
        REMARKS: null,
        Station_Na: 'Jaypurhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.02960977819448, 25.10414681975579],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 41,
        SL_NO: 41,
        STATIONNAM: 'PURAMAPAIL',
        REMARKS: null,
        Station_Na: 'Puramapail',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.02958683405183, 25.155817208780523],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 40,
        SL_NO: 40,
        STATIONNAM: 'PANCHBIBI',
        REMARKS: null,
        Station_Na: 'Panchbibi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.01785263857978, 25.229116628988777],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 39,
        SL_NO: 39,
        STATIONNAM: 'BAGJANA',
        REMARKS: null,
        Station_Na: 'Bagjana',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.01163490545486, 25.26188850151108],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 38,
        SL_NO: 38,
        STATIONNAM: 'HILI',
        REMARKS: null,
        Station_Na: 'Hili',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.01150497994038, 25.2851293817988],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 37,
        SL_NO: 37,
        STATIONNAM: 'DANGAPARA',
        REMARKS: null,
        Station_Na: 'Dangapara',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.0044937297985, 25.35453040318662],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 36,
        SL_NO: 36,
        STATIONNAM: 'BIRAMPUR',
        REMARKS: null,
        Station_Na: 'Birampur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.00012207558227, 25.383973865716538],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 35,
        SL_NO: 35,
        STATIONNAM: 'PHULBARI',
        REMARKS: null,
        Station_Na: 'Phulbari',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.97052007540371, 25.498655383224687],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 34,
        SL_NO: 34,
        STATIONNAM: 'BHOWANIPUR',
        REMARKS: null,
        Station_Na: 'Bhowanipur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.93210594460587, 25.610326674800486],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 49,
        SL_NO: 49,
        STATIONNAM: 'SANTAHAR GOODS',
        REMARKS: null,
        Station_Na: 'Santahar goods',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.97978212567212, 24.760463505380777],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 50,
        SL_NO: 50,
        STATIONNAM: 'HALALIHAT',
        REMARKS: null,
        Station_Na: 'Halalihat',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.97361763177152, 24.72325875091641],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 51,
        SL_NO: 51,
        STATIONNAM: 'RANINGAR',
        REMARKS: null,
        Station_Na: 'Raningar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.9789885537618, 24.676555888779035],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 52,
        SL_NO: 52,
        STATIONNAM: 'SHAHAGOALA',
        REMARKS: null,
        Station_Na: 'Shahagoala',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.98114008476749, 24.63481153122036],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 53,
        SL_NO: 53,
        STATIONNAM: 'ATRAI AHSANGANJ',
        REMARKS: null,
        Station_Na: 'Atrai ahsanganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.97527323338888, 24.597522604497524],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 54,
        SL_NO: 54,
        STATIONNAM: 'BIRKUTSA',
        REMARKS: null,
        Station_Na: 'Birkutsa',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.96829993572956, 24.562015716198857],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 55,
        SL_NO: 55,
        STATIONNAM: 'MADHNAGAR',
        REMARKS: null,
        Station_Na: 'Madhnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.9643096836722, 24.517051494730957],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 56,
        SL_NO: 56,
        STATIONNAM: 'NALDANGARHAT',
        REMARKS: null,
        Station_Na: 'Naldangarhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.96456910465253, 24.471103507588133],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 57,
        SL_NO: 57,
        STATIONNAM: 'BASUDEBPUR',
        REMARKS: null,
        Station_Na: 'Basudebpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.96947493800856, 24.457195056572854],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 58,
        SL_NO: 58,
        STATIONNAM: 'NATOR',
        REMARKS: null,
        Station_Na: 'Nator',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.97035974567767, 24.412759757888313],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 59,
        SL_NO: 59,
        STATIONNAM: 'YASINPUR',
        REMARKS: null,
        Station_Na: 'Yasinpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.96771988034618, 24.358781930717168],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 60,
        SL_NO: 60,
        STATIONNAM: 'MALANCHI',
        REMARKS: null,
        Station_Na: 'Malanchi',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.96709438574398, 24.320347024860915],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 61,
        SL_NO: 61,
        STATIONNAM: 'ABDULPUR',
        REMARKS: 'JUNCTION',
        Station_Na: 'Abdulpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.96729287097334, 24.271051141964495],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 62,
        SL_NO: 62,
        STATIONNAM: 'LOKMANPUR',
        REMARKS: null,
        Station_Na: 'Lokmanpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.93543235454885, 24.27939632334588],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 63,
        SL_NO: 63,
        STATIONNAM: 'ARANI',
        REMARKS: null,
        Station_Na: 'Arani',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.89889523734345, 24.285314835921234],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 64,
        SL_NO: 64,
        STATIONNAM: 'INANDAGACHI',
        REMARKS: null,
        Station_Na: 'Inandagachi',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.8554764659295, 24.291925222879435],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 65,
        SL_NO: 65,
        STATIONNAM: 'SARDAROAD',
        REMARKS: null,
        Station_Na: 'Sardaroad',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.80858610160706, 24.312902176202147],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 66,
        SL_NO: 66,
        STATIONNAM: 'BELPUKUR',
        REMARKS: null,
        Station_Na: 'Belpukur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.70356762413776, 24.37509357684975],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 67,
        SL_NO: 67,
        STATIONNAM: 'HARIAN',
        REMARKS: null,
        Station_Na: 'Harian',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.67307292416258, 24.373981280753554],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 68,
        SL_NO: 68,
        STATIONNAM: 'RAJSHAHI UNIVERSITY',
        REMARKS: null,
        Station_Na: 'Rajshahi university',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.65002449061832, 24.37281253631562],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 69,
        SL_NO: 69,
        STATIONNAM: 'RAJSHAHI',
        REMARKS: null,
        Station_Na: 'Rajshahi',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.61060336411236, 24.37151931405456],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 70,
        SL_NO: 70,
        STATIONNAM: 'RAJSHAHICOURT',
        REMARKS: null,
        Station_Na: 'Rajshahicourt',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.56449128235678, 24.37991718253442],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 71,
        SL_NO: 71,
        STATIONNAM: 'SITLAI',
        REMARKS: null,
        Station_Na: 'Sitlai',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.52901447664149, 24.420387107439247],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 72,
        SL_NO: 72,
        STATIONNAM: 'CHIBBISNAGAR',
        REMARKS: null,
        Station_Na: 'Chibbisnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.49446863379737, 24.471172589928074],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 73,
        SL_NO: 73,
        STATIONNAM: 'KAKUNHAT',
        REMARKS: null,
        Station_Na: 'Kakunhat',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.46993241427795, 24.512617278314302],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 74,
        SL_NO: 74,
        STATIONNAM: 'LALITNAGAR',
        REMARKS: null,
        Station_Na: 'Lalitnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.43824762904657, 24.559572393462364],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 75,
        SL_NO: 75,
        STATIONNAM: 'AMNURA',
        REMARKS: null,
        Station_Na: 'Amnura',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.39949796320884, 24.615987769574115],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 76,
        SL_NO: 76,
        STATIONNAM: 'CHAPAI NOWABGANJ',
        REMARKS: null,
        Station_Na: 'Chapai nowabganj',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.27964025564852, 24.596561342257136],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 77,
        SL_NO: 77,
        STATIONNAM: 'NIZAMPUR',
        REMARKS: null,
        Station_Na: 'Nizampur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.41858662678959, 24.681875120503513],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 78,
        SL_NO: 78,
        STATIONNAM: 'NACHOL',
        REMARKS: null,
        Station_Na: 'Nachol',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.39333351050729, 24.741607665667804],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 79,
        SL_NO: 79,
        STATIONNAM: 'GOALBARI',
        REMARKS: null,
        Station_Na: 'Goalbari',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.3481903623769, 24.798261737804562],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 80,
        SL_NO: 80,
        STATIONNAM: 'ROHANPUR',
        REMARKS: null,
        Station_Na: 'Rohanpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.32227333405982, 24.82205765406333],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 82,
        SL_NO: 82,
        STATIONNAM: 'AZIMPUR',
        REMARKS: null,
        Station_Na: 'Azimpur',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.99077599095921, 24.231603424990574],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: null,
        REMARKS: null,
        Station_Na: 'Tangail',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.93743416801333, 24.273724304240954],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: null,
        REMARKS: null,
        Station_Na: 'Mohera',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.03144650389306, 24.168320877955804],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: null,
        REMARKS: null,
        Station_Na: 'Mirjapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [90.10150320072147, 24.105372236023328],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: null,
        REMARKS: null,
        Station_Na: null,
      },
      geometry: {
        type: 'Point',
        coordinates: [90.28148241307831, 24.039036923380884],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: 'HEMNAGAR',
        REMARKS: null,
        Station_Na: 'Hemnagar',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.85990498426781, 24.568507437052947],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: 'TARAKANDI',
        REMARKS: null,
        Station_Na: 'Tarakandi',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.82383742420308, 24.684449156273267],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: 'BHUAPUR',
        REMARKS: null,
        Station_Na: 'Bhuapur',
      },
      geometry: {
        type: 'Point',
        coordinates: [89.86725220794095, 24.467302906574563],
      },
    },
    {
      type: 'Feature',
      properties: {
        STN_ID: 0,
        SL_NO: 0,
        STATIONNAM: 'NAVARON',
        REMARKS: null,
        Station_Na: 'Navaron',
      },
      geometry: {
        type: 'Point',
        coordinates: [88.9939581678115, 23.055769561592957],
      },
    },
  ],
}
