export const dhakaFireStations = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.37033860000008, 23.875691800000027, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Fire Service & Civil Defence',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.40482040000006, 23.82711480000006, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Kurmitola Fire Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.36655430000008, 23.80738720000005, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Mirpur Fire Service Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.42457290000004, 23.79821960000004, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Fire Service & Civil Defence Station, Baridhara',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.40044960000006, 23.764695300000028, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Fire Service and Civil Defense, Tejgaon Fire Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.34934500000008, 23.782327800000076, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Kallyanpur Fire Service & Civil Defence Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.37364830000007, 23.75877360000004, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Bangladesh Fire Service and Civil Defense',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.40610990000005, 23.722825700000044, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Bangladesh Fire Service and Civil Defense Head Quarter',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.40933620000004, 23.707006900000067, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Sadarghat Fire Service and Civil Defense Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.38930060000007, 23.72561950000005, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Fire Service and Civil Defense Station, Palashi',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.41843970000008, 23.70504130000006, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Sutrapur Fire Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.43040320000006, 23.69187720000008, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Postogola Fire Service Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.43091310000005, 23.746021400000075, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Khilgaon Fire Station & Civil Defense Station',
        FolderPath: 'Fire Services',
        SymbolID: 0,
        AltMode: -1,
        Base: 0,
        Snippet: '',
        PopupInfo: '',
        HasLabel: -1,
        LabelID: 0,
      },
    },
  ],
}