import {GoogleMap, useJsApiLoader, Marker, TrafficLayer} from '@react-google-maps/api'
import {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import LocationDetails from './locationDetails/LocationDetails'

const SingleGoogleMap = () => {
  const [center, setCenter] = useState({})
  const libraries = ['places']
  const {currentUser} = useAuth()

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  })

  useEffect(() => {
    if (currentUser?.station) {
      setCenter({lat: currentUser?.station?.latitude, lng: currentUser?.station?.longitude})
    } else {
      setCenter({lat: 23.7778, lng: 90.4057})
    }
  }, [currentUser])

  if (!isLoaded) {
    return <h1 className='map-container'>Loading...</h1>
  }
  return (
    <>
      <GoogleMap
        center={center}
        zoom={13}
        mapContainerStyle={{width: '100%', height: '100%'}}
        options={{streetViewControl: false, mapTypeControl: false}}
      >
        <Marker position={center} />
        <TrafficLayer autoUpdate />
        {currentUser && <LocationDetails info={currentUser} />}
      </GoogleMap>
    </>
  )
}

export default SingleGoogleMap
