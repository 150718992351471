import './LocationDetails.scss'

const LocationDetails = ({info, routeInfo}) => {
  return (
    <>
      <div className='card p-3 location-details'>
        <div className='d-flex justify-content-start align-items-center'>
          <div className=''>Station Name</div>
          <div className='ms-4 text-red-500'>: {info.station?.name}</div>
          {routeInfo?.distance && (
            <div className='ms-5 badge-container'>
              {info?.station?.order_number === 1 && (
                <>
                  {!info?.togglePrimaryStation && (
                    <span className='primary-badge'>Primary Station</span>
                  )}
                  {info?.togglePrimaryStation && (
                    <span className='not-respond'>Did Not Respond</span>
                  )}
                </>
              )}
              {info?.station?.order_number === 2 && (
                <>
                  {!info?.togglePrimaryStation && !info?.standby && (
                    <span className='standby-badge'>Standby Station</span>
                  )}
                  {info?.togglePrimaryStation && !info?.standby && (
                    <span className='primary-badge'>New Primary Station</span>
                  )}
                  {!info?.togglePrimaryStation && info?.standby && (
                    <span className='primary-badge'>New Primary Station</span>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {/* <div className='fw-bolder fs-1'>Address: {info.station?.address}</div> */}
        {info.incident_place && (
          <div className='d-flex'>
            <div className=''>Incident Place</div>
            <div className='ms-3 text-red-500'>: {info.incident_place?.address}</div>
          </div>
        )}
        {routeInfo && (
          <div className='d-flex'>
            <div>Distance</div>
            <div className='ms-85 text-red-500 custom-margin'>: {routeInfo?.distance?.text}</div>
          </div>
        )}
        {routeInfo && (
          <div className='d-flex'>
            <div>Duration</div>
            <div className='ms-85 text-red-500 custom-margin'>: {routeInfo?.duration?.text}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default LocationDetails
