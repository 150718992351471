import React from 'react'
import './HeadQuarter.scss'

const HeadQuarter = () => {
  return (
    <section className='card p-5 page-container'>
      <h2 className='mb-5'>Head Quarter</h2>
      <table class='table table-striped table-hover border border-1'>
        <thead>
          <tr className='bg-primary text-white fw-bold'>
            <th className='text-center'>SL</th>
            <th>Name</th>
            <th>Address</th>
            <th>Contact Number</th>
            <th>Total Employees</th>
            <th>Incident Place Photo</th>
            <th>Nearest Water Source</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='text-center'>1</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td className='text-center'>2</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td className='text-center'>3</td>
            <td>Larry the Bird</td>
            <td>Larry the Bird</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td className='text-center'>4</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td className='text-center'>5</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td className='text-center'>6</td>
            <td>Larry the Bird</td>
            <td>Larry the Bird</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td className='text-center'>7</td>
            <td>Larry the Bird</td>
            <td>Larry the Bird</td>
            <td>01232323232</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default HeadQuarter
