import {LayersControl, LayerGroup, Marker, Tooltip, useMap} from 'react-leaflet'

const MarkerLayerWIthTooltip = ({data}) => {
  const map = useMap()
  const layer = data.features.map((feature) => {
    const {coordinates} = feature.geometry
    return (
      <Marker
        key={String(coordinates)}
        position={[coordinates[1], coordinates[0]]}
        // icon={redIcon}
        doFitToBounds={true}
        eventHandlers={{
          click: (e) => map.panTo(e.latlng),
        }}
      >
        <Tooltip>
          <h6>{feature.properties.Name}</h6>
        </Tooltip>
      </Marker>
    )
  })
  return (
    <LayersControl.Overlay checked name='Dhaka Fire Stations'>
      <LayerGroup>{layer}</LayerGroup>
    </LayersControl.Overlay>
  )
}

export default MarkerLayerWIthTooltip
