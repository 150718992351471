import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const NotificationModal = ({show, handleClose, notification}) => {
  // console.log(notification)
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {notification?.is_new_incident && <h1 className='text-danger'>Incident Details</h1>}
          {!notification?.is_new_incident && <h1 className='text-info'>Acknowledgement Details</h1>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>
          Incident Place:{' '}
          <span className='text-danger'>{notification?.incident_place?.address}</span>
        </h2>
        {/* <h3>Primary Station: {notification?.station?.name}</h3> */}
        {notification?.others?.accept_by_other &&
          notification?.others?.can_skip &&
          !notification?.others?.is_new_incident &&
          notification?.station?.order_number === 2 && (
            <h1>
              The Call is received by <span className='text-success'>Primary Station</span>
            </h1>
          )}
        {notification?.others?.accept_by_other &&
          notification?.others?.can_skip &&
          !notification?.others?.is_new_incident &&
          notification?.station?.order_number === 1 && (
            <h1>
              The Call is forwarded to <span className='text-success'>Secondary Station</span>
            </h1>
          )}
        {!notification?.others?.accept_by_other &&
          !notification?.others?.can_skip &&
          !notification?.others?.is_new_incident && (
            <h1>
              The Call is denied by <span className='text-danger'>Primary Station</span>, Standby
              station must accept the call
            </h1>
          )}

        <div className='d-flex justify-content-end'>
          <Button className='mt-25' variant='danger' size='sm' onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotificationModal
