import {LayersControl, MapContainer, TileLayer} from 'react-leaflet'
import {savarFireStation} from '../data/savarFireStations'
import {dhakaFireStations} from '../data/dhakaFirestations'
import {railwayStations} from '../data/Rail_Stations'
import SavarFireStations from '../layers/SavarFireStations'
import DhakaFireStations from '../layers/DhakaFireStations'
import RailwayStations from '../layers/RailwayStations'
import 'leaflet.fullscreen/Control.FullScreen.js'
import 'leaflet.fullscreen/Control.FullScreen.css'

const LeafletMap = ({position}) => {
  return (
    <MapContainer
      className='card map-container'
      fullscreenControl={true}
      center={position}
      zoom={12}
      scrollWheelZoom={true}
    >
      <LayersControl position='topright'>
        <LayersControl.BaseLayer checked name='OSM Streets'>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='ESRI World Imagery'>
          <TileLayer
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          />
        </LayersControl.BaseLayer>
        <SavarFireStations data={savarFireStation} />
        <DhakaFireStations data={dhakaFireStations} />
        <RailwayStations data={railwayStations} />
      </LayersControl>
      {/* <LeafletRoutingMachine /> */}
    </MapContainer>
  )
}

export default LeafletMap
