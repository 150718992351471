import React from 'react'
import './FireStations.scss'

const FireStations = () => {
  return (
    <section className='card p-5 page-container'>
      <h2 className='mb-5'>Fire Stations</h2>
      <table class='table table-striped table-hover border border-1'>
        <thead>
          <tr className='bg-primary text-white fw-bold'>
            <th className='text-center'>SL</th>
            <th>Name</th>
            <th>Address</th>
            <th>Contact Number</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='text-center'>1</td>
            <td>Station 1</td>
            <td>Otto</td>
            <td>01232323232</td>
          </tr>
          <tr>
            <td className='text-center'>2</td>
            <td>Station 2</td>
            <td>Thornton</td>
            <td>01232323232</td>
          </tr>
          <tr>
            <td className='text-center'>3</td>
            <td>Station 3</td>
            <td>Larry the Bird</td>
            <td>01232323232</td>
          </tr>
          <tr>
            <td className='text-center'>4</td>
            <td>Station 4</td>
            <td>Otto</td>
            <td>01232323232</td>
          </tr>
          <tr>
            <td className='text-center'>5</td>
            <td>Station 5</td>
            <td>Thornton</td>
            <td>01232323232</td>
          </tr>
          <tr>
            <td className='text-center'>6</td>
            <td>Station 6</td>
            <td>Larry the Bird</td>
            <td>01232323232</td>
          </tr>
          <tr>
            <td className='text-center'>7</td>
            <td>Station 7</td>
            <td>Larry the Bird</td>
            <td>01232323232</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default FireStations
