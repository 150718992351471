import L from 'leaflet'
import redLocationIcon from '../../../../_sos/assets/images/marker-red.png'

export const redIcon = new L.Icon({
  iconUrl: redLocationIcon,
  iconRetinaUrl: redLocationIcon,
  iconSize: [38, 38],
  iconAnchor: [17, 16],
  tooltipAnchor: [15, -5],
})
