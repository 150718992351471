import LeafletMap from './components/LeafletMap'
import './LeafletMapContainer.scss'

const Map = () => {
  const position = [23.7778, 90.4057]

  return (
    <div className='row'>
      <div className='col-12'>
        <LeafletMap position={position} />
      </div>
    </div>
  )
}

export default Map
