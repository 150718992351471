import React, {createContext, useEffect, useState} from 'react'
import {doc, onSnapshot, deleteDoc, collection} from 'firebase/firestore'
import {firestore} from '../../firebase'
import {useAuth} from '../modules/auth'
import {stringToObject, dateToMillis} from '../../_sos/utils/conversionUtil'

export const NotificationsContext = createContext()

export const NotificationProvider = ({children}) => {
  const [incidentInfo, setIncidentInfo] = useState([])
  const [ongoingIncident, setOngoingIncident] = useState([])
  const [modalRerender, setModalRerender] = useState(true)
  const {currentUser} = useAuth()

  const deleteAllNotifications = async () => {
    // Delete notification from fire store
    if (currentUser) {
      await Promise.all(
        incidentInfo.map(async (notification) => {
          await deleteDoc(
            doc(
              firestore,
              'incident-notifications',
              currentUser.user_id,
              'user-notifications',
              notification.id
            )
          )
        })
      )
    }
    setOngoingIncident([])
  }
  const deleteNotifications = async (deleteList) => {
    // Delete notification from fire store
    if (currentUser) {
      await Promise.all(
        incidentInfo.map(async (notification) => {
          if (deleteList.includes(notification.id)) {
            await deleteDoc(
              doc(
                firestore,
                'incident-notifications',
                currentUser.user_id,
                'user-notifications',
                notification.id
              )
            )
          }
        })
      )
    }
    // Filter ongoing incident
    setOngoingIncident(
      ongoingIncident.filter((incident) => {
        return !deleteList.includes(incident.id)
      })
    )
    setModalRerender(false)
  }

  const alterPrimaryStation = () => {
    // If primary station did not receive the call then changes in hq
    const superUser =
      currentUser?.role === 'Head quarter' || currentUser?.role === 'ADN Head Quarter'
    if (
      !incidentInfo[0]?.others?.accept_by_other &&
      !incidentInfo[0]?.others?.can_skip &&
      superUser &&
      (incidentInfo[0]?.station?.order_number === 1 || incidentInfo[0]?.station?.order_number === 2)
    ) {
      ongoingIncident.find(
        (incident) =>
          incident?.incident_place.id === incidentInfo[0]?.incident_place.id &&
          incident?.station?.order_number === 1
      )['togglePrimaryStation'] = true
      ongoingIncident.find(
        (incident) =>
          incident?.incident_place.id === incidentInfo[0]?.incident_place.id &&
          incident?.station?.order_number === 2
      )['standby'] = true
    }
    // If primary station did not receive the call then changes in secondary station
    if (
      !incidentInfo[0]?.others?.accept_by_other &&
      !incidentInfo[0]?.others?.can_skip &&
      incidentInfo[0]?.station?.order_number === 2
    ) {
      ongoingIncident.find(
        (incident) => incident?.incident_place.id === incidentInfo[0]?.incident_place.id
      )['togglePrimaryStation'] = true
    }
    // If primary station did not receive the call then changes in primary station
    if (
      incidentInfo[0]?.others?.accept_by_other &&
      incidentInfo[0]?.others?.can_skip &&
      incidentInfo[0]?.station?.order_number === 1
    ) {
      ongoingIncident.find(
        (incident) => incident?.incident_place.id === incidentInfo[0]?.incident_place.id
      )['togglePrimaryStation'] = true
    }
  }

  useEffect(() => {
    let unsubscribe
    if (currentUser) {
      unsubscribe = onSnapshot(
        collection(
          firestore,
          'incident-notifications', //db name
          currentUser.user_id,
          'user-notifications' //table name
        ),
        (snapshot) => {
          const notification = snapshot.docs.map((doc) => {
            const innerValue = doc._document.data.value.mapValue.fields
            // console.log('new notification')
            return {
              created_at: dateToMillis(innerValue.created_at.timestampValue),
              id: innerValue.id.stringValue,
              incident_place: stringToObject(innerValue.incident_place.stringValue),
              others: stringToObject(innerValue.others.stringValue),
              station: stringToObject(innerValue.station.stringValue),
            }
          })
          setIncidentInfo(notification.sort((a, b) => b.created_at - a.created_at))
          setModalRerender(true)
        }
      )
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (incidentInfo?.length > 0) {
      if (incidentInfo[0]?.others?.is_new_incident) {
        setOngoingIncident([...ongoingIncident, incidentInfo[0]])
      }
      if (incidentInfo?.length > 0 && ongoingIncident.length === 0) {
        setOngoingIncident(incidentInfo.filter((incident) => incident.others.is_new_incident))
      }
      if (
        ongoingIncident?.length > 0 &&
        !incidentInfo[0]?.others?.is_new_incident &&
        !incidentInfo[0]?.others?.is_reset
      ) {
        alterPrimaryStation()
      }
    }
  }, [incidentInfo])

  return (
    <NotificationsContext.Provider
      value={{
        incidentInfo,
        ongoingIncident,
        modalRerender,
        deleteNotifications,
        setModalRerender,
        deleteAllNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
