export const savarFireStation = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.24129980000004, 23.779449300000067, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Fire service and civil defence station',
        Catetgory: '',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [90.25898660000007, 23.828477100000043, 0],
      },
      properties: {
        OID_: 0,
        Name: 'Fire Service & Civil Defence Station - Savar',
        Catetgory: '',
      },
    },
  ],
}