import {LayersControl, LayerGroup, Marker, Popup} from 'react-leaflet'

const RailwayStations = ({data}) => {
  const layer = data.features.map((feature) => {
    const {coordinates} = feature.geometry
    return (
      <Marker
        key={String(coordinates)}
        position={[coordinates[1], coordinates[0]]}
        // icon={redIcon}
        doFitToBounds={true}
      >
        <Popup>
          <h3>{feature.properties.STATIONNAM}</h3>
        </Popup>
      </Marker>
    )
  })
  return (
    <LayersControl.Overlay name='Railway Stations'>
      <LayerGroup>{layer}</LayerGroup>
    </LayersControl.Overlay>
  )
}

export default RailwayStations
