import {redIcon} from '../components/redIcon'
import {LayersControl, LayerGroup, Marker, Popup, useMap} from 'react-leaflet'

const MarkerLayer = ({data}) => {
  const map = useMap()
  const layer = data.features.map((feature) => {
    const {coordinates} = feature.geometry
    return (
      <Marker
        key={String(coordinates)}
        position={[coordinates[1], coordinates[0]]}
        doFitToBounds={true}
        icon={redIcon}
        eventHandlers={{
          click: (e) => map.panTo(e.latlng),
        }}
      >
        {/* <Popup>
          <h3>This is for testing</h3>
        </Popup> */}
        <Popup>
          <h6>{feature.properties.Name}</h6>
        </Popup>
      </Marker>
    )
  })
  return (
    <LayersControl.Overlay checked name='Savar Fire Stations'>
      <LayerGroup>{layer}</LayerGroup>
    </LayersControl.Overlay>
  )
}

export default MarkerLayer
