import React, {useState} from 'react'
import './IncidentPlaces.scss'

const IncidentPlaces = () => {
  const tableData = [
    {
      name: 'Bonani',
      address: 'Road 2, Bonani',
      contactNumber: '0171353535',
      totalEmployees: 150,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Uttora',
      address: 'Section 7, Uttora',
      contactNumber: '0174553535',
      totalEmployees: 100,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Uttora',
      address: 'Section 5, Uttora',
      contactNumber: '0165553535',
      totalEmployees: 180,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Dhanmondi',
      address: 'Bhutergoli',
      contactNumber: '01684353535',
      totalEmployees: 188,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Kalabagan',
      address: '1st Lane',
      contactNumber: '01713990535',
      totalEmployees: 200,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Mohakhali',
      address: 'Bus Stand',
      contactNumber: '0134563535',
      totalEmployees: 400,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Malibag',
      address: 'Malibag',
      contactNumber: '019877535',
      totalEmployees: 400,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
    {
      name: 'Gulshan',
      address: 'Road 2, Bonani',
      contactNumber: '0167893535',
      totalEmployees: 560,
      photoURL: 'N/A',
      waterSource: 'Hatir jhil',
    },
  ]
  const [searchKey, setSearchKey] = useState('')

  const searchResult = tableData.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key].toString().toLowerCase().includes(searchKey.toLowerCase())
    )
  })
  return (
    <section className='card p-5 page-container incident-places'>
      <div className='row mb-5'>
        <div className='col-md-6'>
          <h2 className='mt-3'>Incident Places</h2>
        </div>
        <div className='col-md-6 d-flex justify-content-end'>
          <div className='search-box'>
            <input
              className='form-control'
              type='text'
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              id='search-input'
            />
            <span className='set-icon-position'>
              <i className='fa fa-search'></i>
            </span>
          </div>
        </div>
      </div>
      <table className='table table-striped table-hover border border-1'>
        <thead>
          <tr className='bg-primary text-white fw-bold'>
            <th className='text-center'>SL</th>
            <th>Name</th>
            <th>Address</th>
            <th>Contact Number</th>
            <th>Total Employees</th>
            <th>Incident Place Photo</th>
            <th>Nearest Water Source</th>
          </tr>
        </thead>
        <tbody>
          {searchResult.map((data, index) => (
            <tr key={index}>
              <td className='text-center'>{index + 1}</td>
              <td>{data.name}</td>
              <td>{data.address}</td>
              <td>{data.contactNumber}</td>
              <td>{data.totalEmployees}</td>
              <td>{data.photoURL}</td>
              <td>{data.waterSource}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}

export default IncidentPlaces
