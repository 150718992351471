// import axios from 'axios'
import {FC} from 'react'
// import {useIntl} from 'react-intl'
import GoogleMapContainer from '../../modules/google-map/GoogleMapContainer'

const DashboardPage: FC = () => (
  <>
    {/* <LeafletMapContainer /> */}
    <GoogleMapContainer />
  </>
)

const DashboardWrapper: FC = () => {
  // const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
