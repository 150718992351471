import {useContext, useEffect, useState} from 'react'
import {NotificationsContext} from '../../context/NotificationsContext'
import './GoogleMapContainer.scss'
import SingleGoogleMap from './components/SingleGoogleMap'
import MapWithNoIncident from './components/MapWithNoIncident'
import NotificationModal from './modals/notificationModal/NotificationModal'
import {useAuth} from '.././../modules/auth'

const GoogleMapContainer = () => {
  const {currentUser} = useAuth()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // Modal info
  const [modalInfo, setModalInfo] = useState([])
  // Context
  const {
    incidentInfo,
    ongoingIncident,
    deleteNotifications,
    deleteAllNotifications,
    modalRerender,
  } = useContext(NotificationsContext)

  // Get deletable ids
  const removeNotification = (notification) => {
    if (!(currentUser.role === 'Head quarter' || currentUser.role === 'ADN Head Quarter')) {
      if (ongoingIncident?.length === 1) {
        deleteAllNotifications()
      } else if (ongoingIncident?.length > 1) {
        const list = incidentInfo
          .filter((incident) => incident.incident_place.id === notification.incident_place.id)
          .map((incident) => incident.id)

        deleteNotifications(list)
      }
    }
  }

  useEffect(() => {
    if (incidentInfo.length > 0 && ongoingIncident.length > 0) {
      // console.log(incidentInfo[0])
      if (currentUser.role === 'Head quarter' || currentUser.role === 'ADN Head Quarter') {
        setModalInfo(incidentInfo[0])
        handleShow()
      } else {
        if (!incidentInfo[0]?.others?.is_new_incident) {
          // console.log(incidentInfo[0])
          if (!incidentInfo[0]?.others?.is_reset) {
            setModalInfo(incidentInfo[0])
            handleShow()
          }
          // If there is a completion notification
          if (incidentInfo[0]?.others?.is_reset) {
            setModalInfo([])
            removeNotification(incidentInfo[0])
          }
        }
      }
    }
  }, [incidentInfo, currentUser, ongoingIncident])

  return (
    <section className='row map-container pr-0 extra-width'>
      {modalInfo && incidentInfo?.length > 0 && ongoingIncident?.length > 0 && modalRerender && (
        <NotificationModal show={show} handleClose={handleClose} notification={modalInfo} />
      )}
      {ongoingIncident?.map((incident, index) => (
        <div
          key={index}
          className={
            ongoingIncident.length === 1
              ? 'col-12'
              : ongoingIncident.length === 2
              ? 'col-6'
              : 'col-4'
          }
        >
          <SingleGoogleMap incident={incident} />
        </div>
      ))}
      {ongoingIncident?.length === 0 && (
        <div className='col-12'>
          <MapWithNoIncident />
        </div>
      )}
    </section>
  )
}

export default GoogleMapContainer
