import {useContext} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './ResetConfirmationModal.scss'
import {resetIncident} from '../../apiRequest/request'
import {NotificationsContext} from '../../../../context/NotificationsContext'
import {errorToast, successToast} from '../../../../../_sos/utils/toastService'

const ResetConfirmationModal = ({show, handleClose, notification, currentUser}) => {
  // Context
  const {incidentInfo, ongoingIncident, deleteNotifications, deleteAllNotifications} =
    useContext(NotificationsContext)

  const deleteNotificationsForSuperUsers = () => {
    if (ongoingIncident?.length === 1 || ongoingIncident?.length === 2) {
      deleteAllNotifications()
      successToast('Incident Reset Successful')
    }
    if (ongoingIncident?.length > 2) {
      const list = incidentInfo
        .filter((incident) => incident.incident_place.id === notification.incident_place.id)
        .map((incident) => incident.id)

      deleteNotifications(list)
    }
  }

  const deleteNotificationsForStations = async () => {
    await resetIncident(notification)
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          // successToast('Incident Reset Successful')
        }
      })
      .catch((error) => {
        errorToast('Unable to Rest Incident')
      })
  }

  const markAsComplete = () => {
    handleClose()
    if (currentUser.role === 'Head quarter' || currentUser.role === 'ADN Head Quarter') {
      deleteNotificationsForSuperUsers()
      deleteNotificationsForStations()
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className='text-info'>Completion of Incident</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>
          Are you sure? You Want to mark
          <span className='text-danger'> {notification?.incident_place?.address} </span>
          incident as <span className='text-success'>complete?</span>
        </h2>

        <div className='d-flex justify-content-end gap-2'>
          <Button className='mt-25' variant='success' size='sm' onClick={markAsComplete}>
            Yes
          </Button>
          <Button className='mt-25' variant='danger' size='sm' onClick={handleClose}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ResetConfirmationModal
